import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@materialPurchaseOrder/API_FILTER',
	getApi: '@materialPurchaseOrder/GET_API',
	paginationChange: '@materialPurchaseOrder/PAGINATION_CHANGE',
	getCountApi: '@materialPurchaseOrder/GET_COUNT_API',
};

const url = lbBaseUrl + '/material-purchase-orders';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});
