import React from 'react';
import {Button, Card, Col, Row, Table} from 'antd';
import {SiteStatus, type Site} from 'app/models';
import {Link} from 'react-router-dom';
import {uiPaths} from 'app/constants';
import {toTitleCase} from 'app/helpers';
import {FilterFormType} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';

type Props = {
	sites: Site[];
};

export const ViewAllProfileSite: React.FC<Props> = ({sites}) => {
	const [filterValue, setFilterValue] = React.useState<FilterDataType>({status: '', name: ''});

	const underDesign = sites.filter((s: Site) => s.status === SiteStatus.UNDER_DESIGN);
	const underConstruction = sites.filter((s: Site) => s.status === SiteStatus.UNDER_CONSTRUCTION);
	const underWarranty = sites.filter((s: Site) => s.status === SiteStatus.UNDER_WARRANTY);
	const recentlyCompleted = sites.filter((s: Site) => s.status === SiteStatus.RECENTLY_COMPLETED);
	const outOfWarranty = sites.filter((s: Site) => s.status === SiteStatus.OUT_OF_WARRANTY);

	const filterFn = (s: Site) => {
		const matchesStatus = !filterValue.status || String(s.status) === String(filterValue.status);
		const matchesName = !filterValue.name || s.name.toLowerCase().includes(String(filterValue.name).toLowerCase());
		return matchesStatus && matchesName;
	};

	const compareFn = (a: Site, b: Site) => a.name > b.name ? 1 : -1;

	const dataSource: Site[] = [
		{id: 0, projectNumber: 0, name: 'Under Design', address: ''},
		...underDesign.filter(filterFn).sort(compareFn),
		{id: 0, projectNumber: 0, name: 'Under Construction', address: ''},
		...underConstruction.filter(filterFn).sort(compareFn),
		{id: 0, projectNumber: 0, name: 'Under Warranty', address: ''},
		...underWarranty.filter(filterFn).sort(compareFn),
		{id: 0, projectNumber: 0, name: 'Recently Completed', address: ''},
		...recentlyCompleted.filter(filterFn).sort(compareFn),
		{id: 0, projectNumber: 0, name: 'Out Of Warranty', address: ''},
		...outOfWarranty.filter(filterFn).sort(compareFn),
	];

	const statusOptions = Object.values(SiteStatus)
		.map(role => ({value: String(role), label: toTitleCase(role as string, '_') ?? ''}));

	const uiFilters = [
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Site Name',
			label: 'Site Name',
			defaultValue: filterValue?.name,
		},
		{
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Status',
		},
	];

	return (
		<Row>
			<Col span={24} className={'mb-10'}>
				<Card size='small' title={'Important note'}>
					Following are the site assigned to you. Please contact <b>Training Manager</b> for any correction.
				</Card>
			</Col>
			<Col span={24}>
				<FilterView
					uiFilters={uiFilters}
					submitOnChange={true}
					onSubmit={filter => {
						setFilterValue(filter);
					}}
				/>
			</Col>
			<Col span={24}>
				<Button>{`${dataSource.length - 5} Results`}</Button>
				<br /><br />
				<Table
					size={'small'}
					bordered={true}
					dataSource={dataSource}
					pagination={false}
					scroll={{x: 400, y: 500}}
				>
					<Table.Column
						title='Site Name'
						dataIndex='name'
						key='name'
						render={(name: string, s: Site) => s.id ? (
							<Link to={{pathname: `/${uiPaths.siteDetail.replace(':siteId', s.id.toString())}`}}>
								{name}
							</Link>
						) : (
							<b>{name}</b>
						)}
					/>
					<Table.Column
						title='Address'
						dataIndex='address'
						key='address'
					/>
				</Table>
			</Col>
		</Row>
	);
};
