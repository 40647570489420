import axios from 'axios';
import {lbBaseUrl} from 'app/constants';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	resetState: '@site/RESET_STATE',
	getApi: '@site/GET_API',
	getCountApi: '@site/GET_COUNT_API',
	paginationChange: '@workOrder/PAGINATION_CHANGE',
	apiFilter: '@workOrder/API_FILTER',
	getProjectNumber: '@site/GET_PROJECT_NUMBER',
	getSingleApi: '@site/GET_SINGLE_API',
	createApi: '@site/CREATE_API',
	updateApi: '@site/UPDATE_API',
	removeApi: '@site/REMOVE_API',
	getProjectFiles: '@site/GET_PROJECT_FILES',
};

const url = `${lbBaseUrl}/sites`;

export const reset = () => ({
	type: actionTypes.resetState,
	meta: {},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const getProjectNumber = () => ({
	type: actionTypes.getProjectNumber,
	payload: axios.get(`${url}/project-number`, getHeaders(true)),
	meta: {process: true},
});

export const save = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});

export const remove = (id: number) => ({
	type: actionTypes.removeApi,
	payload: axios.delete(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const saveClients = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.post(`${url}/${id}/clients`, data, getHeaders(true)),
	meta: {process: true},
});

export const getProjectFiles = (siteId: number, fileType?: string) => {
	const queryParams = fileType ? `?fileType=${fileType}` : '';
	return {
		type: actionTypes.getProjectFiles,
		payload: axios.get(
			`${url}/${siteId}/project-files${queryParams}`,
			getHeaders(true),
		),
		meta: {process: true},
	};
};
