import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Button,
	Col,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {getById, create, update, getParents} from './actions';
import {useHistory, useParams} from 'react-router';
import {parseNum} from 'app/helpers';
import type Types from 'MyTypes';
import {type MaterialMaster} from './material-master';
import {type FilterOptionItem} from 'app/models/ui-filter';

type MyParams = {
	id?: string;
};

export const ViewMaterialMasterForm: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<MyParams>();

	const {loading, byIds, parents, dataUpdated, errorMessage}
        = useSelector((state: Types.RootState) => state.materialMaster);

	const id = parseNum(params?.id);
	const material = byIds[id] ?? undefined;

	const [name, setName] = React.useState(
		material?.name ?? '',
	);
	const [parentId, setParentId] = React.useState(
		material?.parentId ?? 0,
	);

	React.useEffect(() => {
		dispatch(getParents());
	}, []);

	React.useEffect(() => {
		if (id) {
			dispatch(getById(id));
		}
	}, [id]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (id) {
				void message.success('Updated Successfully.');
				dispatch(getById(id));
			} else {
				history.goBack();
			}
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		if (!name) {
			void message.error('Please enter a name!');
			return;
		}

		const data: Omit<MaterialMaster, 'id'> = {
			parentId,
			name,
		};

		if (material?.id) {
			dispatch(update(material.id, data));
		} else {
			dispatch(create(data));
		}
	};

	const layout = {
		labelCol: {span: 5},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	const parentOptions: FilterOptionItem[] = parents.map(item => ({
		value: String(item.id),
		label: item.name,
	}));

	return (
		<DefaultLayout currentPath={uiPaths.addMaterialMaster}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
                            Material Master Form
						</Typography.Title>
						<Button className='mb-15' onClick={() => {
							history.goBack();
						}}>
                            Back
						</Button>
						<Form {...layout} name='basic'>
							<Form.Item label='Parent (If Any)' name='parentId'>
								<Select
									showSearch={true}
									optionFilterProp='children'
									placeholder='Select a Parent'
									defaultValue={
										parentId
											? parentOptions.find(
												opt => parseNum(opt.value) === parentId,
											)?.label
											: ''
									}
									onChange={value => {
										setParentId(parseNum(value));
									}}
								>
									{parentOptions.map((item: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={item.value}>
											{item.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								label='Name'
								rules={[{required: true, message: 'Please Enter Name'}]}
							>
								<Input
									defaultValue={name}
									value={name}
									onChange={(e: any) => {
										setName(String(e.target.value));
									}}
								/>
							</Form.Item>

							<Form.Item {...tailLayout}>
								<Button type='primary' onClick={onSubmitClicked}>
                                    Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
