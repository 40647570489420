import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {CSVLink} from 'react-csv';
import {count, get, onPaginationChange, setFilterValue, approveAll, cancelAll} from './actions';
import {Button, Col, Empty, Input, List, Modal, Popconfirm, Row, Space, Spin, Table, Tooltip, Typography} from 'antd';
import {
	formatDateFunction,
	getCurrencyString,
	getPermissionSites,
	parseNum,
	toTitleCase,
} from 'app/helpers';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {
	billStatusLabels,
	WorkOrderBillStatus,
	type Site,
	WorkOrderBillType,
	type WorkOrder,
	type WorkOrderBill,
	billTypeLabels,
	SiteStatus,
	ModuleName,
	ModulePermission,
	billTypeWorkType,
	type WorkOrderDebit,
} from 'app/models';
import {getQueryDateRange} from 'app/query_helpers';
import {uiPaths} from 'app/constants';
import {PaginationView} from '../Common/Pagination/PaginationView';
import {BillAmountView} from './ViewWorkOrderBillDetail';
import {CheckCircleOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router';

type Props = {
	toBeApproved?: boolean;
};

export const ViewAllWorkOrderBill: React.FC<Props> = ({toBeApproved}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
	const [cancelReason, setCancelReason] = React.useState<string>('');
	const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
	const [showApproveModal, setShowApproveModal] = React.useState<boolean>(false);
	const {
		filterValue,
		dataUpdated,
		currentPos,
		perPageSize,
		allIds,
		byIds,
		loading,
		totalCount,
	} = useSelector((state: Types.RootState) => state.workOrderBill);
	const {user: authUser, subordinateOptions: userOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);

	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER_BILL,
		ModulePermission.READ,
		allSites,
	);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, [filterValue]);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue, currentPos, perPageSize);
			} else {
				filterData();
			}
		}
	}, [dataUpdated]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleApproveAllClick = () => {
		setShowApproveModal(true);
	};

	const handleApproveAllBillOk = () => {
		setShowApproveModal(false);
		const ids = selectedRowKeys.map(key => parseNum(key));
		if (ids?.length) {
			dispatch(approveAll(ids));
		}
	};

	const handleApproveAllBillCancel = () => {
		setShowApproveModal(false);
	};

	const handleRejectAllClick = () => {
		setShowCancelModal(true);
	};

	const handleRejectAllBillOk = () => {
		setShowCancelModal(false);
		const ids = selectedRowKeys.map(key => parseNum(key));
		if (ids?.length) {
			dispatch(cancelAll(ids, {cancelReason}));
		}
	};

	const handleRejectAllBillCancel = () => {
		setShowCancelModal(false);
	};

	const handlePrintClick = (id: number) => {
		history.push({
			pathname: `/${uiPaths.workOrderBillPrint.replace(
				':id',
				String(id),
			)}`,
		});
	};

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
			include: [{relation: 'penalties'}, {relation: 'debits'}],
		};

		const orQuery: any[] = [];

		if (toBeApproved) {
			filter.where.nextApprovedBy = authUser?.id;
		} else if (!f.siteId && !f.siteStatus) {
			orQuery.push({
				createdBy: {
					inq: [
						...userOptions
							.filter(({value}: FilterOptionItem) => value)
							.map(({value}: FilterOptionItem) => parseInt(value, 10)),
						authUser?.id,
					],
				},
			});
			orQuery.push({
				siteId: {inq: sites.map(({id}) => id)},
			});
		}

		if (orQuery.length) {
			filter.where.and = [{or: orQuery}];
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.siteStatus) {
			filter.where.siteId = {
				inq: sites
					.filter(({status}) => status === f.siteStatus)
					.map(({id}) => id),
			};
		}

		if (f.billType) {
			filter.where.workOrderBillType = f.billType;
		}

		if (f.date1 && f.date2) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(
				...getQueryDateRange(f.date1 as string, f.date2 as string, 'createdAt'),
			);
		}

		if (f.billStatus) {
			filter.where.orderBillStatus = f.billStatus;
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const getCsvData = (data: WorkOrderBill[]) => [
		[
			'Bill id',
			'Bill Submission Time',
			'Site',
			'Site Status',
			'Bill Type',
			'Work Type',
			'Contractor',
			'Bill Amount',
			'Bill Status',
		],
		...data.map(d => [
			d.id,
			formatDateFunction(d.createdAt ?? ''),
			d?.site?.name,
			d?.site?.status,
			d.workOrderBillType,
			billTypeWorkType[d.workOrderBillType ?? ''],
			d.workOrder?.contractor?.name ?? '',
			d?.billAmount,
			d.orderBillStatus,
		]),
	];

	const bills: WorkOrderBill[] = allIds.map(
		(id: number) => ({
			...{key: String(id)},
			...byIds[id],
		}));

	const siteOptions = sites
		.filter((site: Site) =>
			filterValue?.siteStatus ? site.status === filterValue?.siteStatus : true,
		)
		.map((site: Site) => ({value: site.id.toString(), label: site.name}));

	const billStatusOptions = Object.values(WorkOrderBillStatus).map(
		status => ({
			value: String(status),
			label: billStatusLabels[status] ?? '',
		}),
	);

	const billTypeOptions = Object.values(WorkOrderBillType).map(role => ({
		value: String(role),
		label: toTitleCase(role as string, '_') ?? '',
	}));

	const siteStatusOptions: FilterOptionItem[] = Object.values(SiteStatus).map(
		role => ({
			value: String(role),
			label: toTitleCase(role as string, '_') ?? '',
		}),
	);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			items: [],
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
		{
			filterKey: 'billType',
			items: billTypeOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Type',
			label: 'Bill Type',
			defaultValue: filterValue?.billType,
		},
	];

	if (!toBeApproved) {
		uiFilters.push({
			filterKey: 'billStatus',
			items: billStatusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Bill Status',
			defaultValue: filterValue?.billStatus,
		});
	}

	if (siteOptions.length > 1) {
		uiFilters.push(
			{
				filterKey: 'siteId',
				items: siteOptions,
				formType: FilterFormType.SITE_SELECT,
				formWidth: 240,
				placeholder: 'Select Site',
				label: 'Site',
				defaultValue: filterValue?.siteId,
			},
			{
				filterKey: 'siteStatus',
				items: siteStatusOptions,
				formType: FilterFormType.SELECT,
				formWidth: 140,
				placeholder: 'Select Status',
				label: 'Site Status',
				defaultValue: filterValue?.siteStatus,
			},
		);
	}

	const csvData: any[] = getCsvData(bills);

	const ApprovalText = () => {
		const ids = selectedRowKeys.map(key => parseNum(key));
		const bills: WorkOrderBill[] = ids.map(id => byIds[id]);

		const debits = bills.reduce((debits: WorkOrderDebit[], bill: WorkOrderBill) => {
			if (bill?.workOrder?.debits?.length) {
				debits.push(...bill.workOrder.debits.filter(debit => !debit.workOrderBillId));
			}

			return debits;
		}, []);

		const visibleDebits = debits.slice(0, 5); // Show only the first 5 debits
		const remainingCount = debits.length - visibleDebits.length; // Calculate remaining items

		return (
			<Space direction='vertical'>
				<label>
					Please note that these are the debits or advances against the selected work order
					and have you also mentioned the relevant debit on this work order. Do you still want to approve these bills?
				</label>
				{debits.length ? (
					<List
						dataSource={visibleDebits}
						renderItem={debit => (
							<List.Item key={debit.id}>
								<div>{debit.description}</div>
							</List.Item>
						)}
					/>
				) : (
					<b>No current debits or advances</b>
				)}
				{remainingCount > 0 && (
					<Typography.Text>
						{`and ${remainingCount} more`}
					</Typography.Text>
				)}
			</Space>
		);
	};

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={f => {
						filterData(f);
					}} />
					<br />
				</Col>

				<Col span={12} className='mb-10'>
					{selectedRowKeys.length ? (
						<Space>
							<Popconfirm
								title={`Are you sure you want to approve ${selectedRowKeys.length} bills?`}
								onConfirm={() => {
									handleApproveAllClick();
								}}
							>
								<Button type={'primary'}>Approve All</Button>
							</Popconfirm>
							<Popconfirm
								title={`Are you sure you want to cancel ${selectedRowKeys.length} bills?`}
								onConfirm={() => {
									handleRejectAllClick();
								}}
							>
								<Button type={'primary'}>Cancel All</Button>
							</Popconfirm>
						</Space>
					) : (
						[]
					)}
				</Col>
			</Row>

			<Row>
				<Col span={24} className='mb-10' style={{textAlign: 'right'}}>
					<Space>
						<CSVLink
							data={csvData}
							filename={'All_Bills.csv'}
							className='btn btn-primary'
						>
							<Button type='primary'>Download</Button>
						</CSVLink>
					</Space>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					{totalCount ? (
						<Row>
							<Col span={24}>
								<PaginationView
									isFunctional={true}
									total={totalCount}
									currentPos={currentPos ?? 1}
									perPageSize={perPageSize ?? 10}
									pageSizeOptions={['10', '50', '100', '500', '1000', '5000']}
									filterValue={filterValue}
									filterData={filterData}
									onPaginationChange={onPaginationChange}
								/>
								<Table
									bordered={true}
									size={'small'}
									dataSource={bills}
									pagination={false}
									scroll={{x: 1200, y: 500}}
									rowSelection={
										toBeApproved
											? {
												selectedRowKeys,
												onChange: onSelectChange,
											}
											: undefined
									}
								>
									<Table.Column
										title='Bill Id'
										dataIndex='id'
										key='id'
										width={80}
									/>
									<Table.Column
										title='Bill Submitted Time'
										dataIndex='createdAt'
										key='createdAt'
										width={100}
										render={(createdAt: string | undefined) =>
											formatDateFunction(createdAt ?? '')
										}
									/>
									<Table.Column
										title='Site'
										dataIndex='site'
										key='siteName'
										render={(site: Site | undefined) => site?.name ?? ''}
									/>
									<Table.Column
										title='Site Status'
										dataIndex='site'
										key='siteStatus'
										render={(site: Site | undefined) =>
											toTitleCase(site?.status, '_') ?? ''
										}
									/>
									<Table.Column
										title='Bill Type'
										dataIndex='workOrderBillType'
										key='workOrderBillType'
										render={(
											workOrderBillType: WorkOrderBillType | undefined,
											bill: WorkOrderBill,
										) => (
											<Space>
												{bill.debitRecover ? (
													<Tooltip title='Amount recovered'>
														<CheckCircleOutlined />
													</Tooltip>
												) : (
													[]
												)}
												{workOrderBillType
													? billTypeLabels[workOrderBillType]
													: ''}
											</Space>
										)}
									/>
									<Table.Column
										title='Work Type'
										dataIndex='workOrder'
										key='workOrder'
										render={(
											order: WorkOrder | undefined,
											bill: WorkOrderBill,
										) =>
											order?.projectProcessMaster?.processName
											?? bill.projectProcessMaster?.processName
											?? billTypeWorkType[bill.workOrderBillType ?? '']
										}
									/>
									<Table.Column
										title='Contractor'
										dataIndex='workOrder'
										key='workOrder'
										render={(
											order: WorkOrder | undefined,
											bill: WorkOrderBill,
										) =>
											order ? order.contractor?.name : bill.contractor?.name
										}
									/>
									<Table.Column
										title='Bill Amount'
										dataIndex='billAmount'
										key='billAmount'
										render={(
											billAmount: number | undefined,
											bill: WorkOrderBill,
										) => (billAmount ? <BillAmountView bill={bill} /> : '')}
									/>
									<Table.Column
										title='Remaining Debits'
										dataIndex='remainingDebits'
										key='remainingDebits'
										render={(text: string, bill: WorkOrderBill) => {
											const remainingDebits = bill?.workOrder?.debits?.filter(debit => !debit.workOrderBillId) ?? [];
											return remainingDebits.length ? (
												<ul style={{margin: 0, paddingLeft: 16, listStyle: 'disc'}}>
													{remainingDebits.map((debit, i) => (
														<li key={debit.id}>{`${debit.description}: ${getCurrencyString(debit.amount, false)}`}</li>
													))}
												</ul>
											) : '-';
										}}
									/>
									<Table.Column
										title='Bill Status'
										dataIndex='action'
										key='action'
										width={160}
										render={(text: string, bill: WorkOrderBill) => (
											<Space direction='vertical'>
												<Button
													type={'primary'}
													onClick={() => {
														history.push({
															pathname: `/${uiPaths.workOrderBillDetail.replace(
																':id',
																String(bill.id),
															)}`,
														});
													}}
												>
													View Detail
												</Button>
												<Button
													type={'primary'}
													onClick={() => {
														handlePrintClick(bill.id);
													}}
												>
													Print
												</Button>
												<span>{toTitleCase(bill.orderBillStatus, '_')}</span>
												<small>
													{bill.nextApprovedUser
														? `Pending Approval from ${bill.nextApprovedUser.name}`
														: ''}
													{/* {bill.orderBillStatus === WorkOrderBillStatus.PRE_APPROVED ? `Pending Approval from ${toTitleCase(UserRole.CONTRACT_DEVELOPMENT_AND_RELATIONSHIP_MANAGER, '_')}` : ''} */}
													{/* {bill.orderBillStatus === WorkOrderBillStatus.APPROVED ? `Pending Approval from ${toTitleCase(UserRole.CONSTRUCTION_HEAD, '_')}` : ''} */}
													{bill.orderBillStatus === WorkOrderBillStatus.APPROVED
														? 'Payment is Required'
														: ''}
													{bill.orderBillStatus
														=== WorkOrderBillStatus.CANCELLED
														? `Reason - ${bill.cancelReason ?? 'N/A'}`
														: ''}
												</small>
											</Space>
										)}
									/>
								</Table>
							</Col>
						</Row>
					) : (
						<Row>
							<Col span={24} style={{textAlign: 'center'}}>
								<Empty />
							</Col>
						</Row>
					)}
				</Col>
			</Row>
			<Modal
				title='Cancel Bill'
				open={showCancelModal}
				onOk={handleRejectAllBillOk}
				onCancel={handleRejectAllBillCancel}
			>
				<Space direction={'vertical'}>
					<label>Please prove a reason to cancel this bill</label>
					<Input.TextArea
						placeholder={'Enter Cancel Reason'}
						defaultValue={cancelReason ?? ''}
						value={cancelReason ?? ''}
						onChange={(e: any) => {
							setCancelReason(String(e.target.value));
						}}
					/>
				</Space>
			</Modal>

			<Modal
				title='Approve Bill'
				open={showApproveModal}
				onOk={handleApproveAllBillOk}
				onCancel={handleApproveAllBillCancel}
			>
				<ApprovalText />
			</Modal>
		</Spin>
	);
};
