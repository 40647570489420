import React, {useState} from 'react';
import {Input, Select, Button, Table} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {type MaterialPurchaseOrder} from '../MaterialPurchaseOrder/material-purchase-order';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {type MaterialMaster} from '../MaterialMaster/material-master';
import {getCurrencyString, parseNum} from 'app/helpers';

type Props = {
	materialQueryId: number;
	siteId: number;
	materialMasters: MaterialMaster[];
	vendors: FilterOptionItem[];
	onChange: (items: MaterialPurchaseOrder[]) => void;
	initialValues?: MaterialPurchaseOrder[];
};

const PurchaseOrderForm: React.FC<Props> = ({
	materialQueryId,
	siteId,
	materialMasters,
	vendors,
	onChange,
}) => {
	const newItem: MaterialPurchaseOrder = {
		itemId: 0,
		costHeadId: 0,
		quantity: 0,
		vendorId: 0,
		basePrice: 0,
		gstPercent: 0,
		totalCost: 0,
		id: 0,
		siteId,
		materialQueryId,
	};
	const [items, setItems] = useState<MaterialPurchaseOrder[]>([newItem]);

	// Get cost heads (parent items) from material masters
	const costHeads = materialMasters.filter(item => item.parentId === 0);

	const calculateTotal = (item: Partial<MaterialPurchaseOrder>): number => {
		const {basePrice = 0, quantity = 0, gstPercent = 0} = item;
		const baseAmount = basePrice * quantity;
		const gstAmount = (baseAmount * gstPercent) / 100;
		return baseAmount + gstAmount;
	};

	const addItem = () => {
		setItems([...items, newItem]);
	};

	const removeItem = (index: number) => {
		const updatedItems = [...items];
		updatedItems.splice(index, 1); // Remove item at index
		setItems(updatedItems);
		onChange(updatedItems);
	};

	const updateItem = (index: number, field: keyof MaterialPurchaseOrder, value: any) => {
		const updatedItems = [...items];
		const item = updatedItems[index];

		if (item) {
			// Update the item at the specific index
			updatedItems[index] = {
				...item,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				[field]: value,
			};

			// If itemId is changed, find and set the corresponding costHeadId
			if (field === 'itemId') {
				const materialItem = materialMasters.find(m => m.id === value);
				if (materialItem) {
					updatedItems[index].costHeadId = materialItem.parentId ?? 0;
				}
			}

			// Recalculate total amount if relevant fields change
			if (['basePrice', 'quantity', 'gstPercent'].includes(field)) {
				updatedItems[index].totalCost = calculateTotal(updatedItems[index]);
			}

			setItems(updatedItems);
			onChange(updatedItems);
		}
	};

	const columns = [
		{
			title: 'Item Name',
			dataIndex: 'itemId',
			key: 'itemId',
			width: 200,
			render: (itemId: number, record: MaterialPurchaseOrder, index: number) => (
				<Select
					style={{width: '100%'}}
					value={itemId || undefined}
					onChange={value => {
						updateItem(index, 'itemId', value);
					}}
				>
					{materialMasters
						.filter(item => item.parentId !== 0)
						.map(item => (
							<Select.Option key={item.id} value={item.id}>
								{item.name}
							</Select.Option>
						))}
				</Select>
			),
		},
		{
			title: 'Cost Head',
			dataIndex: 'costHeadId',
			key: 'costHeadId',
			render(costHeadId: number) {
				const costHead = costHeads.find(ch => ch.id === costHeadId);
				return costHead?.name ?? '';
			},
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			render: (quantity: number, record: MaterialPurchaseOrder, index: number) => (
				<Input
					type='number'
					value={quantity || ''}
					onChange={e => {
						updateItem(index, 'quantity', Number(e.target.value));
					}}
				/>
			),
		},
		{
			title: 'Vendor',
			dataIndex: 'vendorId',
			key: 'vendorId',
			width: 200,
			render: (vendorId: number, record: MaterialPurchaseOrder, index: number) => (
				<Select
					style={{width: '100%'}}
					value={vendorId || undefined}
					onChange={value => {
						updateItem(index, 'vendorId', value);
					}}
				>
					{vendors.map(vendor => (
						<Select.Option key={vendor.value} value={vendor.value}>
							{vendor.label}
						</Select.Option>
					))}
				</Select>
			),
		},
		{
			title: 'Base Price (₹)',
			dataIndex: 'basePrice',
			key: 'basePrice',
			render: (basePrice: number, record: MaterialPurchaseOrder, index: number) => (
				<Input
					type='number'
					value={basePrice || ''}
					onChange={e => {
						updateItem(index, 'basePrice', Number(e.target.value));
					}}
				/>
			),
		},
		{
			title: 'GST (%)',
			dataIndex: 'gstPercent',
			key: 'gstPercent',
			render: (gstPercent: number, record: MaterialPurchaseOrder, index: number) => (
				<Input
					type='number'
					value={gstPercent || ''}
					onChange={e => {
						updateItem(index, 'gstPercent', Number(e.target.value));
					}}
				/>
			),
		},
		{
			title: 'Total Amount',
			dataIndex: 'totalCost',
			key: 'totalCost',
			render: (totalCost: number) => getCurrencyString(totalCost, false),
		},
		{
			title: 'Action',
			key: 'action',
			render: (_: any, record: MaterialPurchaseOrder, index: number) => (
				<Button
					type='link'
					danger
					icon={<DeleteOutlined />}
					onClick={() => {
						removeItem(index);
					}}
				/>
			),
		},
	];

	const totalOrderAmount = items.reduce((sum, item) => sum + item.totalCost, 0);

	return (
		<div style={{width: '100%'}}>
			<Table
				dataSource={items}
				columns={columns}
				pagination={false}
				rowKey='id'
				footer={() => (
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
						<Button
							type='primary'
							icon={<PlusOutlined />}
							onClick={addItem}
						>
                            Add Item
						</Button>
						<strong>Total Order Amount: {getCurrencyString(totalOrderAmount, false)}</strong>
					</div>
				)}
			/>
		</div>
	);
};

export default PurchaseOrderForm;
