import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {
	Button,
	Card,
	Col,
	message,
	Modal,
	Row,
	Select,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
} from 'antd';
import {uiPaths} from 'app/constants';
import {get, setFilterValue, update, updateSchedules} from './actions';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {Link} from 'react-router-dom';
import {
	formatDateFunction,
	getCurrencyString,
	getPermissionSites,
	parseNum,
	toSubstring,
	toTitleCase,
} from 'app/helpers';
import {
	SiteStatus,
	type Site,
	WorkOrderScheduleStatus,
	WorkOrderStatus,
	type WorkOrderSchedule,
	ModuleName,
	ModulePermission,
} from 'app/models';
import {getQueryDateRange} from 'app/query_helpers';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {type ProjectProcessMaster} from 'app/components/ProjectProcessMaster/project-process-master';
import {useHistory} from 'react-router';

export const ViewAllWorkOrderSchedule: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const {allIds, byIds, loading, filterValue, dataUpdated} = useSelector(
		(state: Types.RootState) => state.workOrderSchedule,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);

	const [updateStatusId, setUpdateStatusId] = useState<number>(0);
	const [newStatus, setNewStatus] = useState<string>('');

	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER,
		ModulePermission.READ,
		allSites,
	);
	const addWorkOrderSites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER,
		ModulePermission.WRITE,
		allSites,
	);

	const viewBudgetSites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER_BUDGET,
		ModulePermission.READ,
		allSites,
	);
	const today: Date = new Date();
	const monthAhead: Date = new Date(
		new Date(new Date().setDate(new Date().getDate() + 30)),
	);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue);
			}
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {
			where: {},
			limit: 100,
		};

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		} else {
			filter.where.siteId = {inq: sites.map(({id}) => id)};
		}

		if (f.siteStatus) {
			filter.where.siteId = {
				inq: sites
					.filter(({status}) => status === f.siteStatus)
					.map(({id}) => id),
			};
		}

		if (f.workOrderStatus) {
			filter.where.status = f.workOrderStatus;
		}

		if (f.date1 && f.date2) {
			filter.where.and = [
				...getQueryDateRange(f.date1 as string, f.date2 as string, 'scheduleDate'),
			];
		}

		dispatch(setFilterValue(f));
		dispatch(get(filter));
	};

	const handleUpdateStatusSave = () => {
		if (!newStatus) {
			void message.error('Please select a status');
			return;
		}

		if (!updateStatusId) {
			void message.error('Something went wrong. Please try again later.');
			return;
		}

		const data = {
			status: newStatus,
		};
		dispatch(update(updateStatusId, data));
		setUpdateStatusId(0);
		setNewStatus('');
	};

	const handleUpdateStatusCancel = () => {
		setUpdateStatusId(0);
		setNewStatus('');
	};

	const handleUpdateSchedulesClick = (siteId: number) => {
		if (
			// eslint-disable-next-line no-alert
			window.confirm(
				'Are you sure you want to update the schedules. This will create new schedule only and will not populate the dates',
			)
		) {
			dispatch(updateSchedules(siteId));
		}
	};

	let items = allIds?.length ? allIds.map(id => byIds[id]) : [];

	if (filterValue.isMissing) {
		items = items.filter(
			item =>
				(item.budgetAmount && !item.scheduleDate)
        ?? (!item.budgetAmount && item.scheduleDate),
		);
	}

	items = items.filter(
		item =>
			item.status?.toUpperCase() !== (WorkOrderScheduleStatus.STOPPED).toUpperCase(),
	);

	const dataSource = [
		...items
			.filter(item => item.scheduleDate)
			.sort((a: WorkOrderSchedule, b: WorkOrderSchedule) => {
				if (a.scheduleDate && b.scheduleDate) {
					return new Date(a.scheduleDate) > new Date(b.scheduleDate) ? 1 : -1;
				}

				return -1;
			}),
		...items.filter(item => !item.scheduleDate),
	].map((record: WorkOrderSchedule) => {
		const workOrders
      = record.workOrders?.length ? record.workOrders.filter(workOrder => workOrder.createdBy === authUser?.id) : [];
		if (!workOrders.length) {
			record.workOrderAmount = '*N/A';
			record.workOrderQuantity = '*N/A';
		}

		let amt = 0;
		let qty = 0;
		for (const workOrder of workOrders) {
			if (workOrder.workOrderAmount) {
				amt += workOrder.workOrderAmount;
			} else {
				record.workOrderAmount = '*N/A';
			}

			if (workOrder.workOrderQuantity) {
				qty += workOrder.workOrderQuantity;
			} else {
				record.workOrderQuantity = '*N/A';
			}
		}

		if (!record.workOrderAmount) {
			const color
        = amt && record.budgetAmount && amt > record.budgetAmount ? 'red' : '';
			record.workOrderAmount = (
				<p style={{color}}>
					{getCurrencyString(amt, false)}{' '}
					{color === 'red' ? (
						<Tooltip title='Work order amount exceeds the budgeted amount.'>
							<QuestionCircleOutlined />
						</Tooltip>
					) : (
						[]
					)}
				</p>
			);
		}

		if (!record.workOrderQuantity) {
			record.workOrderQuantity = `${qty} ${record.budgetUnit ?? ''}`;
		}

		return record;
	});

	const tagColor = {
		[WorkOrderStatus.CREATED]: '',
		[WorkOrderStatus.APPROVED]: 'orange',
		[WorkOrderStatus.PUBLISHED]: 'green',
		[WorkOrderStatus.CANCELLED]: 'red',
		[WorkOrderStatus.TERMINATED]: 'volcano',
		[WorkOrderStatus.TERMINATION_IN_PROGRESS]: 'cyan',
		[WorkOrderStatus.COMPLETED]: 'lime',
		[WorkOrderStatus.PENDING]: 'yellow',
	};

	const siteOptions = sites
		.filter((site: Site) =>
			filterValue?.siteStatus ? site.status === filterValue?.siteStatus : true,
		)
		.map((site: Site) => ({value: site.id.toString(), label: site.name}));

	const statusOptions = Object.values(WorkOrderScheduleStatus).map(role => ({
		value: String(role),
		label: toTitleCase(role as string, '_') ?? '',
	}));

	const siteStatusOptions: FilterOptionItem[] = Object.values(SiteStatus).map(
		role => ({
			value: String(role),
			label: toTitleCase(role as string, '_') ?? '',
		}),
	);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue.siteId,
		},
		{
			filterKey: 'siteStatus',
			items: siteStatusOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Status',
			label: 'Site Status',
			defaultValue: filterValue?.siteStatus,
		},
		{
			filterKey: 'workOrderStatus',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			placeholder: 'Select Status',
			label: 'Work Type Status',
			defaultValue: filterValue?.workOrderStatus,
		},
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Scheduled Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
		{
			filterKey: 'isMissing',
			formType: FilterFormType.BOOLEAN,
			label: 'Missing Budget/Schedule Date',
			defaultValue: filterValue.isMissing,
		},
	];

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
					<br />
				</Col>
			</Row>
			{authUser?.isAdmin && filterValue.siteId ? (
				<Row>
					<Col span={24} style={{textAlign: 'right', marginBottom: 10}}>
						<Space>
							<Button
								type={'primary'}
								onClick={() => {
									handleUpdateSchedulesClick(parseNum(filterValue.siteId as string));
								}}
							>
                Update Schedules
							</Button>
						</Space>
					</Col>
				</Row>
			) : (
				[]
			)}
			<Row>
				<Col span={24}>
					<Row>
						<Button>{`${dataSource.length} Results`}</Button>
						{addWorkOrderSites.length > 0 && (
							<h4 style={{marginLeft: '20px', marginTop: '5px'}}>
								{' '}
								<Space direction='horizontal'>
                  Work Order Status:
									<div>
										<Tag color='yellow'>Pending</Tag>
										<Tag color='green'>Published</Tag>
										<Tag color='red'>Cancelled</Tag>
										<Tag color='volcano'>Terminated</Tag>
										<Tag color='lime'>Completed</Tag>
										<Tag color='cyan'>Termination in Progress</Tag>
									</div>
								</Space>
							</h4>
						)}
					</Row>
					<br />
					<br />
					<Table
						id='WorkOrderSchedulesTable'
						size={'small'}
						bordered={true}
						dataSource={dataSource}
						pagination={false}
						scroll={{x: 750, y: 500}}
					>
						<Table.Column
							title='Site'
							dataIndex='site'
							key='site'
							render={(site: Site | undefined) => site?.name ?? ''}
						/>
						<Table.Column
							title='Work Type'
							dataIndex='projectProcessMaster'
							key='projectProcessMaster'
							render={(
								projectProcessMaster: ProjectProcessMaster | undefined,
								record: WorkOrderSchedule,
							) => (
								<>
									{projectProcessMaster?.processName ?? 'N/A'}
									<Tag color={'blue'}>{toTitleCase(record.status, '_')}</Tag>
								</>
							)}
						/>
						<Table.Column
							title='Date by which Contractor is to be Appointed as per project schedule'
							dataIndex='scheduleDate'
							key='scheduleDate'
							render={(
								scheduleDate: string | undefined,
								record: WorkOrderSchedule,
							) => {
								let color = '';
								const publishOrderCount = record.workOrders?.length
									? record.workOrders.filter(
										workOrder =>
											workOrder.status === WorkOrderStatus.PUBLISHED,
									).length
									: 0;
								if (
									scheduleDate
                  && record.status !== WorkOrderScheduleStatus.ISSUED
                  && new Date(scheduleDate) < monthAhead
                  && new Date(scheduleDate) > today
                  && !publishOrderCount
								) {
									color = 'orange';
								}

								if (
									scheduleDate
                  && record.status !== WorkOrderScheduleStatus.ISSUED
                  && new Date(scheduleDate) < today
                  && !publishOrderCount
								) {
									color = 'red';
								}

								return (
									<Tag color={color}>
										<Space>
											{formatDateFunction(scheduleDate, false)}
											{color === 'red' ? (
												<Tooltip title="Contractor hasn't been appointed before schedule date.">
													<QuestionCircleOutlined />
												</Tooltip>
											) : (
												[]
											)}
										</Space>
									</Tag>
								);
							}}
						/>
						<Table.Column
							title='Budget Quantity'
							dataIndex='budget'
							key='budget'
							render={(
								budget: string | undefined,
								record: WorkOrderSchedule,
							) => {
								const canViewBudget = Boolean(viewBudgetSites.find(
									s => s.id === record.siteId,
								));
								if (canViewBudget) {
									return (
										<Space>
											{record.budgetQuantity}
											{record.budgetUnit}
										</Space>
									);
								}

								return '';
							}}
						/>
						<Table.Column
							title='Total Work Order Quantity'
							dataIndex='totalQuantity'
							key='totalQuantity'
							render={(
								totalQuantity: number | undefined,
								record: WorkOrderSchedule,
							) => (
								<Space>
									{totalQuantity}
									{record.budgetUnit}
								</Space>
							)}
						/>
						<Table.Column
							title='Budget Amount'
							dataIndex='budget'
							key='budget'
							render={(
								budget: string | undefined,
								record: WorkOrderSchedule,
							) => {
								const canViewBudget = Boolean(viewBudgetSites.find(
									s => s.id === record.siteId,
								));
								return canViewBudget
									? getCurrencyString(record.budgetAmount, false)
									: '';
							}}
						/>
						<Table.Column
							title='Total Work Order Amount'
							dataIndex='totalAmount'
							key='totalAmount'
							render={(amt: number | undefined, record: WorkOrderSchedule) => {
								if (!amt) {
									return '*N/A';
								}

								const color
                  = amt && record.budgetAmount && amt > record.budgetAmount ? 'red' : '';
								return (
									<p style={{color}}>
										{getCurrencyString(amt, false)}{' '}
										{color === 'red' ? (
											<Tooltip title='Work order amount exceeds the budgeted amount.'>
												<QuestionCircleOutlined />
											</Tooltip>
										) : (
											[]
										)}
									</p>
								);
							}}
						/>
						<Table.Column
							title='Work Order Status'
							width={240}
							dataIndex='action'
							key='action'
							render={(text: string, record: WorkOrderSchedule) => {
								const canAddWorkOrder = Boolean(addWorkOrderSites.find(
									s => s.id === record.siteId,
								));
								const workOrders
                  = record.workOrders?.length ? record.workOrders.filter(workOrder => canAddWorkOrder
                          || workOrder.status === WorkOrderStatus.PUBLISHED
                          || workOrder.status === WorkOrderStatus.COMPLETED
                          || workOrder.status === WorkOrderStatus.TERMINATED) : [];

								return (
									<Space direction={'vertical'}>
										{workOrders.map((workOrder, ix) => (
											<Link
												key={ix}
												to={{
													pathname: `/${uiPaths.workOrderDetail.replace(
														':orderId',
														String(workOrder.id),
													)}`,
												}}
											>
												<Tag
													color={
														workOrder.status ? tagColor[workOrder.status] : ''
													}
												>
													{toSubstring(workOrder.title, 30, '...')}
												</Tag>
											</Link>
										))}
										{record.scheduleDate ? (
											<Space direction={'vertical'}>
												{canAddWorkOrder ? (
													<Link
														to={{
															pathname: `/${uiPaths.createWorkOrderFromSchedule.replace(
																':scheduleId',
																String(record.id),
															)}`,
														}}
													>
														<Button type={'primary'} size={'small'}>
															{record.workOrders?.length
																? 'Appointed More'
																: 'Appoint Work Order'}
														</Button>
													</Link>
												) : (
													[]
												)}
											</Space>
										) : (
											<Space direction={'vertical'}>
												<Tag>Not Applicable</Tag>
												{canAddWorkOrder ? (
													<Button
														type={'default'}
														size={'small'}
														onClick={e => {
															history.push({
																pathname: `/${uiPaths.createWorkOrderFromSchedule.replace(
																	':scheduleId',
																	String(record.id),
																)}`,
															});
														}
														}
													>
                            Forcefully Appoint Work Order
													</Button>
												) : (
													[]
												)}
											</Space>
										)}
									</Space>
								);
							}}
						/>
					</Table>
				</Col>
				<Col span={24} className={'mt-10'}>
					<Card size='small' title={'Note'}>
						<p>
              * For multiple work order if anyone work order has no data
              available, it shows N/A
						</p>
					</Card>
				</Col>
			</Row>
			<Modal
				title='Update Status'
				open={Boolean(updateStatusId)}
				onOk={handleUpdateStatusSave}
				onCancel={handleUpdateStatusCancel}
			>
				<Space>
					<label>Work Order Status</label>
					<Select
						showSearch={true}
						style={{width: 220}}
						placeholder='Select Status'
						optionFilterProp='children'
						onChange={value => {
							setNewStatus(String(value));
						}}
					>
						{statusOptions.map((option: FilterOptionItem, ix: number) => (
							<Select.Option key={ix} value={option.value}>
								{option.label}
							</Select.Option>
						))}
					</Select>
				</Space>
			</Modal>
		</Spin>
	);
};
