import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type Types from 'MyTypes';
import {
	get,
	reset,
	setReApprovedFilterValue,
	count,
	onReApprovedPaginationChange,
	paidAll,
} from './actions';
import {Button, Col, Empty, Row, Space, Spin, Table, Tooltip, message} from 'antd';
import {
	equalNum,
	formatDateFunction,
	getCurrencyString,
	getPermissionSites,
	parseNum,
	toTitleCase,
} from 'app/helpers';
import {
	billTypeLabels,
	billTypeWorkType,
	ModuleName,
	ModulePermission,
	type PaymentMilestone,
	type Site,
	type WorkOrder,
	type WorkOrderBill,
	WorkOrderBillStatus,
	WorkOrderBillType,
} from 'app/models';
import {getQueryDateRange} from 'app/query_helpers';
import {uiPaths} from 'app/constants';
import {paid} from './actions';
import {BillAmountView} from './ViewWorkOrderBillDetail';
import {CheckCircleOutlined} from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {FilterFormType, type UiFilter} from 'app/models/ui-filter';
import {useHistory} from 'react-router';
import {PaginationView} from '../Common/Pagination/PaginationView';

type Props = {
	reportType?: string;
};

export const ViewReApprovedWorkOrderBill: React.FC<Props> = ({reportType}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
	const {
		loading,
		byIds,
		allIds,
		totalCount,
		reApprovedCurrentPos,
		reApprovedPerPageSize,
		reApprovedFilterValue,
		dataUpdated,
	} = useSelector((state: Types.RootState) => state.workOrderBill);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const paySites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER_BILL_PAY,
		ModulePermission.WRITE,
		allSites,
	);

	React.useEffect(() => {
		dispatch(reset());
		if (reApprovedFilterValue) {
			filterData(reApprovedFilterValue);
		} else {
			filterData();
		}
	}, [reApprovedFilterValue]);

	React.useEffect(() => {
		if (reApprovedFilterValue) {
			filterData(reApprovedFilterValue, reApprovedCurrentPos, reApprovedPerPageSize);
		} else {
			filterData();
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['updatedAt DESC'],
			include: [{relation: 'penalties'}, {relation: 'debits'}],
		};

		filter.where.and = [];

		if (reportType === 'client') {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push({billedToClient: true});
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push({or: [
				{billedToClient: false},
				{billedToClient: null},
			]});
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(f.siteId as string);
		}

		if (f.billType) {
			filter.where.workOrderBillType = f.billType;
		}

		if (f.date1 && f.date2) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			filter.where.and.push(...getQueryDateRange(f.date1 as string, f.date2 as string, 'updatedAt'));
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		filter.where.and.push({orderBillStatus: WorkOrderBillStatus.APPROVED});

		dispatch(setReApprovedFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const handlePaidClick = (id: number) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to mark paid.')) {
			dispatch(paid(id));
		}
	};

	const handlePaidAllClick = (ids: number[]) => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Are you sure you want to mark paid to all.')) {
			dispatch(paidAll(ids));
			void message.success('Paid Successfully');
			setSelectedRowKeys([]);
			// Window.location.reload();
		}
	};

	const handleDetailClick = (id: number) => {
		history.push({
			pathname: `/${uiPaths.workOrderBillDetail.replace(
				':id',
				String(id),
			)}`,
		});
	};

	const handlePrintClick = (id: number) => {
		history.push({
			pathname: `/${uiPaths.workOrderBillPrint.replace(
				':id',
				String(id),
			)}`,
		});
	};

	const getNetBillAmount = (bill: WorkOrderBill): number => {
		const penaltyAmount
      = bill.penalties?.length ? bill.penalties.reduce((amt, penalty) => amt + penalty.amount, 0) : 0;
		const debitAmount
      = bill.debits?.length ? bill.debits.reduce((amt, debit) => amt + debit.amount, 0) : 0;
		return bill.billAmount - penaltyAmount - debitAmount;
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const canPayAll = (selectedRowKeys: React.Key[]) => selectedRowKeys.reduce((canPay, key) => {
		// Returning false when any item can not be marked paid.
		if (!canPay) {
			return false;
		}

		const bill = byIds[parseNum(key)];
		if (
			bill.orderBillStatus !== WorkOrderBillStatus.APPROVED
        || !paySites.find(s => equalNum(s.id, bill?.siteId))
		) {
			return false;
		}

		return true;
	}, true);

	const dataSource: WorkOrderBill[] = allIds.map((id: number) => ({
		...{key: String(id)},
		...byIds[id],
	}));

	const csvData: any[] = [
		['S.N.', 'Description', 'Site', 'Work', 'Amount', 'Type'],
	];
	dataSource.forEach((record: WorkOrderBill, ix) => {
		csvData.push([
			String(ix + 1),
			record.workOrder?.contractor?.name ?? record.contractor?.name,
			record.site?.name ?? 'N/A',
			record.workOrder
				? record.workOrder.projectProcessMaster?.processName ?? ''
				: record.projectProcessMaster?.processName ?? '',
			String(getNetBillAmount(record)),
			record.workOrderBillType
				? billTypeLabels[record.workOrderBillType]
				: '',
		]);
	});

	const siteOptions = allSites
		.map((site: Site) => ({value: site.id.toString(), label: site.name}));

	const billTypeOptions = Object.values(WorkOrderBillType).map(role => ({
		value: String(role),
		label: toTitleCase(role as string, '_') ?? '',
	}));

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [
				reApprovedFilterValue?.date1 as string,
				reApprovedFilterValue?.date2 as string,
			],
		},
		{
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: reApprovedFilterValue?.siteId,
		},
		{
			filterKey: 'billType',
			items: billTypeOptions,
			formType: FilterFormType.SELECT,
			formWidth: 140,
			placeholder: 'Select Type',
			label: 'Bill Type',
			defaultValue: reApprovedFilterValue?.billType,
		},
	];

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
				</Col>
			</Row>
			<Row>
				<Col span={12} className='mb-10'>
					<Space>
						{selectedRowKeys.length ? (
							<Button
								type={'primary'}
								disabled={!canPayAll(selectedRowKeys)}
								onClick={() => {
									handlePaidAllClick(
										selectedRowKeys.map(key => parseNum(key)),
									);
								}
								}
							>
                Pay All
							</Button>
						) : (
							[]
						)}
					</Space>
				</Col>
				<Col span={12} className='mb-10' style={{textAlign: 'right'}}>
					<Space>
						<CSVLink data={csvData} filename={'Work_Order_Bill_Report.csv'}>
							<Button type={'primary'}>Download</Button>
						</CSVLink>
					</Space>
				</Col>
			</Row>

			{totalCount ? (
				<Row>
					<Col span={24}>
						<Row>
							<Col span={24}>
								<PaginationView
									isFunctional={true}
									total={totalCount}
									pageSizeOptions={['10', '50', '100', '500', '1000', '5000']}
									currentPos={reApprovedCurrentPos ?? 1}
									perPageSize={reApprovedPerPageSize ?? 10}
									filterValue={reApprovedFilterValue}
									filterData={filterData}
									onPaginationChange={onReApprovedPaginationChange}
								/>
								<Table
									bordered={true}
									size={'small'}
									dataSource={dataSource}
									pagination={false}
									scroll={{x: 800, y: 500}}
									rowSelection={{
										selectedRowKeys,
										onChange: onSelectChange,
									}}
									footer={pageData => (
										<b>
											Total for Current Page:{' '}
											{getCurrencyString(
												pageData.reduce(
													(val, record) => {
														val += getNetBillAmount(record);
														return val;
													},
													0,
												),
												true,
											)}
										</b>
									)}
								>
									<Table.Column
										title='S.N.'
										key='sno'
										width={60}
										render={(_, __, ix) => ix + 1}
									/>
									<Table.Column
										title='Description'
										dataIndex='workOrder'
										key='workOrder'
										render={(order: WorkOrder | undefined, bill: WorkOrderBill) =>
											order ? order.contractor?.name : bill.contractor?.name
										}
									/>
									<Table.Column
										title='Site'
										dataIndex='site'
										key='siteName'
										render={(site: Site | undefined) => site?.name ?? ''}
									/>
									<Table.Column
										title='Work'
										dataIndex='workOrder'
										key='workOrder'
										render={(order: WorkOrder | undefined, bill: WorkOrderBill) =>
											order?.projectProcessMaster?.processName
											?? bill.projectProcessMaster?.processName
											?? billTypeWorkType[bill.workOrderBillType ?? '']
										}
									/>
									<Table.Column
										title='Bill Creation Date'
										dataIndex='createdAt'
										key='billCreationDate'
										render={(createdAt: string | undefined) =>
											createdAt ? formatDateFunction(createdAt, false) : 'N/A'
										}
									/>
									<Table.Column
										title='Milestone / Bill Description'
										dataIndex='milestoneOrDescription'
										key='milestoneOrDescription'
										render={(_: string | undefined, bill: WorkOrderBill) => {
											if (bill.workOrderBillType === WorkOrderBillType.WORK_ORDER) {
												return bill.paymentMilestones?.length ? (
													<ul>
														{bill.paymentMilestones.map((milestone: PaymentMilestone) => (
															<li key={milestone.id}>
																{milestone.name} - {milestone.amount.toLocaleString()}
																{milestone.workOrderBillId ? ' (Paid)' : ''}
															</li>
														))}
													</ul>
												) : (
													'N/A'
												);
											}

											if (
												bill.workOrderBillType === WorkOrderBillType.EXTRA_AMOUNT
												|| bill.workOrderBillType === WorkOrderBillType.ADVANCE_AMOUNT
											) {
												return bill.billDescription ?? 'N/A';
											}

											return 'N/A';
										}}
									/>

									<Table.Column
										title='Amount'
										dataIndex='billAmount'
										key='billAmount'
										render={(billAmount: number | undefined, bill: WorkOrderBill) =>
											billAmount ? <BillAmountView bill={bill} /> : ''
										}
									/>
									<Table.Column
										title='Type'
										dataIndex='workOrderBillType'
										key='workOrderBillType'
										render={(workOrderBillType: WorkOrderBillType | undefined, bill: WorkOrderBill) => (
											<Space>
												{bill.debitRecover ? (
													<Tooltip title='Amount recovered'>
														<CheckCircleOutlined />
													</Tooltip>
												) : null}
												{workOrderBillType ? billTypeLabels[workOrderBillType] : ''}
											</Space>
										)}
									/>
									<Table.Column
										title='Action'
										dataIndex='action'
										key='action'
										render={(action: string | undefined, bill: WorkOrderBill) => (
											<Space>
												{bill.orderBillStatus === WorkOrderBillStatus.APPROVED
												&& paySites.find(s => equalNum(s.id, bill?.siteId)) ? (
														<Button
															type={'primary'}
															onClick={() => {
																handlePaidClick(bill.id);
															}}
														>
															Pay
														</Button>
													) : null}
												<Button
													type={'primary'}
													onClick={() => {
														handleDetailClick(bill.id);
													}}
												>
													View Detail
												</Button>
												<Button
													type={'primary'}
													onClick={() => {
														handlePrintClick(bill.id);
													}}
												>
													Print
												</Button>
											</Space>
										)}
									/>
								</Table>

							</Col>
						</Row>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}
		</Spin>
	);
};
