
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {type User} from 'app/models';
import {
	type DataSourceType,
	ViewHierarchyReport,
} from './ViewHierarchyReport';
import {getQueryDateRangeForDays} from 'app/query_helpers';
import {formatDateFunction, parseNum} from 'app/helpers';
import {getWarrantyQueryReport} from './warranty-query-report-reducer';
import {uiPaths} from 'app/constants';

export const ViewWarrantyQueryReport: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, byIds, allIds} = useSelector(
		(state: Types.RootState) => state.warrantyQueryReport,
	);
	const {users} = useSelector((state: Types.RootState) => state.summary);

	const reports = allIds?.length ? allIds.map(id => byIds[id]) : [];

	// Get set of users who have data
	const usersWithData = new Set(reports.map(report => report.userId));

	// Get unique dates and sort them in descending order
	const uniqueDates = Array.from(
		new Set(
			reports.map(report => formatDateFunction(report.reportDate, false)),
		),
	).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

	// Create columns for each date
	const reportColumns = uniqueDates.map(date => ({
		title: date,
		key: date,
		width: 240,
		children: [
			{
				title: 'Open',
				key: `${date}-open`,
				width: 80,
			},
			{
				title: 'Closed',
				key: `${date}-closed`,
				width: 80,
			},
			{
				title: 'Raised',
				key: `${date}-raised`,
				width: 80,
			},
		],
	}));

	// Build filtered user tree with metrics
	const buildUserTree = (users: User[]): DataSourceType[] => {
		// Check if a user's branch has any data
		const hasDataInBranch = (user: User): boolean => {
			if (usersWithData.has(user.id)) {
				return true;
			}

			const subordinates = users.filter(u => u.reportingUserId === user.id);
			return subordinates.some(sub => hasDataInBranch(sub));
		};

		// Find root users who have data in their branch
		const rootUsers = users
			.filter(user => !user.reportingUserId)
			.filter(user => hasDataInBranch(user));

		// Recursive function to build tree
		const buildUserNode = (user: User): DataSourceType | undefined => {
			// Find all direct reports with data
			const subordinates = users
				.filter(u => u.reportingUserId === user.id)
				.filter(sub => hasDataInBranch(sub));

			// Get user's own reports
			const userReports = reports.filter(r => r.userId === user.id);
			const records: Record<string, number> = {};

			// Calculate metrics for this node
			reportColumns.forEach(column => {
				const dateKey = column.key;
				const dateReport = userReports.find(r =>
					formatDateFunction(r.reportDate, false) === dateKey,
				);

				records[`${dateKey}-open`] = dateReport?.open ?? 0;
				records[`${dateKey}-closed`] = dateReport?.closed ?? 0;
				records[`${dateKey}-raised`] = dateReport?.raised ?? 0;
			});

			// Build children nodes
			const children = subordinates
				.map(sub => buildUserNode(sub))
				.filter((node): node is DataSourceType => node !== null);

			// Add children's metrics to parent
			if (children.length > 0) {
				children.forEach(child => {
					Object.keys(child.records).forEach(key => {
						records[key] = parseNum(records[key] || 0) + parseNum(child.records[key] || 0);
					});
				});
			}

			// Only return node if it has data or has children with data
			if (usersWithData.has(user.id) || children.length > 0) {
				return {
					key: String(user.id),
					name: user.name || '',
					records,
					children: children.length > 0 ? children : undefined,
				};
			}

			return undefined;
		};

		return rootUsers
			.map(user => buildUserNode(user))
			.filter((node): node is DataSourceType => node !== null);
	};

	React.useEffect(() => {
		dispatch(
			getWarrantyQueryReport({
				where: {
					and: getQueryDateRangeForDays(
						new Date().toDateString(),
						0,
						36,
						'reportDate',
					),
				},
				order: ['reportDate DESC'],
			}),
		);
	}, []);

	const dataSource = users.length ? buildUserTree(users) : [];

	return (
		<DefaultLayout currentPath={uiPaths.weeklyWarrantyQueryReport}>
			<ViewHierarchyReport
				title='Warranty Query Report'
				loading={loading}
				columns={reportColumns}
				dataSource={dataSource}
			/>
		</DefaultLayout>
	);
};
