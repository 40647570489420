import React, {useState} from 'react';
import {Input, Button, Card, Spin, Alert} from 'antd';
import axios from 'axios';

const ChecklistSearch = () => {
	const [query, setQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState<string | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	const handleSearch = async () => {
		if (!query.trim()) {
			return;
		}

		setLoading(true);
		setError(undefined);

		try {
			const response = await axios.get('https://us-central1-develop-cheap.cloudfunctions.net/save_checklist/search-checklist', {
				params: {q: query},
			});

			if (response.data.status === 'success') {
				setResult(String(response?.data?.data?.answer));
			}
		} catch (err: any) {
			setError(String(err.response?.data?.message) || 'An error occurred');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div style={{maxWidth: 800, margin: '20px auto', padding: '0 20px'}}>
			<div style={{display: 'flex', gap: '10px', marginBottom: 20}}>
				<Input
					value={query}
					onChange={e => {
						setQuery(e.target.value);
					}}
					placeholder='Enter your search query'
					onPressEnter={handleSearch}
				/>
				<Button type='primary' onClick={handleSearch} loading={loading}>
          Search
				</Button>
			</div>

			{error && (
				<Alert type='error' message={error} style={{marginBottom: 20}} />
			)}

			{loading && (
				<div style={{textAlign: 'center', padding: 20}}>
					<Spin size='large' />
				</div>
			)}

			{result && (
				<Card title='Search Results'>
					<div style={{marginBottom: 20}}>
						<h4>AI Response:</h4>
						<p>{result}</p>
					</div>
				</Card>
			)}
		</div>
	);
};

export default ChecklistSearch;
