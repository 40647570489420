import React, {useState} from 'react';
import {Button, Card, Col, Empty, Form, Input, message, Row, Select, Spin} from 'antd';
import type Types from 'MyTypes';
import {useDispatch, useSelector} from 'react-redux';
import {create, initPage} from './actions';
import {get as getUserPermissions} from 'app/components/UserPermission/actions';
import {checkFileErr, equalNum, parseNum, toTitleCase} from 'app/helpers';
import {type UploadFile} from 'app/models/upload-file';
import {
	ModuleName,
	PriorityType,
	type QueryParentType,
	QueryType,
	type Site,
	type UserPermission,
	VisibilityType,
	type QueryData,
	type AttachmentData,
} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {appAdmin} from 'app/constants';
import {SiteSelect} from '../Common/SiteSelect';

type Props = {
	module?: ModuleName;
	siteId?: number;
	parentId?: string;
	parentType?: QueryParentType;
	workStatusReportId?: number;
	clientDecisionId?: number;
};

export const ViewQueryForm: React.FC<Props> = ({module, siteId: propSiteId, parentId, parentType, workStatusReportId, clientDecisionId}) => {
	const dispatch = useDispatch();

	const {dataUpdated, loading, errorMessage} = useSelector((state: Types.RootState) => state.query);
	const {user: authUser} = useSelector((state: Types.RootState) => state.summary);
	const {allSites: sites, allIds: userPermissionAllIds, byIds: userPermissionByIds} = useSelector((state: Types.RootState) => state.userPermission);

	let querySiteId = 0;
	if (propSiteId) {
		querySiteId = propSiteId;
	} else if (sites && sites.length === 1) {
		querySiteId = sites[0].id;
	}

	const [siteId, setSiteId] = useState<number>(querySiteId);
	const [content, setContent] = useState<string>('');
	const [assignedTo, setAssignedTo] = useState<number>(0);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = useState<Record<string, string>>({});

	React.useEffect(() => {
		dispatch(initPage());
		dispatch(getUserPermissions({
			where: {
				siteId: {inq: sites.map(({id}) => id)}, moduleName: ModuleName.PROJECT_QUERY_ASSIGNMENT,
			},
		}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			setContent('');
			setFileList([]);
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
			dispatch(initPage());
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		if (!assignedTo) {
			void message.error('Please Select Whom to Assign this query');
			return;
		}

		if (!content) {
			void message.error('Please Enter Query Point');
			return;
		}

		checkFileErr(fileList);

		if (!siteId) {
			void message.error('Please Select a Site');
			return;
		}

		const attachments = fileList.map((file: UploadFile) => {
			const x: AttachmentData = {
				name: file.name,
				key: `${filePrefixKeys[file.uid]}/${file.name}`,
			};
			return x;
		});

		const data: QueryData = {
			queryType: QueryType.PROJECT,
			siteId,
			content,
			assignedTo,
			priority: PriorityType.NORMAL,
			workStatusReportId,
			clientDecisionId,
			parentId,
			parentType,
			attachments,
			visibility: VisibilityType.INTERNAL,
		};

		dispatch(create(data));
	};

	const siteOptions: FilterOptionItem[] = sites
		.map((s: Site) => ({value: s.id.toString(), label: s.name}));
	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};
	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};
	const userPermissions = userPermissionAllIds.map(id => userPermissionByIds[id]);
	const assignOptions: FilterOptionItem[] = userPermissions
		.filter(userPermission =>
			!equalNum(userPermission.userId, authUser?.id) && equalNum(userPermission.siteId, siteId))
		.reduce((assignOptions: FilterOptionItem[], userPermission: UserPermission) => {
			const u = userPermission?.user;
			if (u?.id && u?.name && !assignOptions.find(opt => equalNum(opt.value, u?.id))) {
				assignOptions.push({label: `${u.name} (${toTitleCase(u.roles)})`, value: String(u.id)});
			}

			return assignOptions;
		}, []);

	if (!siteOptions?.length) {
		return (
			<Row>
				<Col span={24} style={{textAlign: 'center'}}>
					<Empty description={`Sorry, You have not been assigned any project yet. Please reach out to Prithu Admin ${appAdmin}`} />
				</Col>
			</Row>
		);
	}

	return (
		<Spin
			size='large'
			spinning={loading}
			tip={'Loading...'}
		>
			<Row className='mb-15'>
				<Col span={24}>
					<Form
						{...layout}
						name='basic'
					>
						<Form.Item label='Query Point*'>
							<Input.TextArea
								id={'content'}
								rows={3}
								placeholder={'Enter Your Query'}
								value={content ?? ''}
								onChange={(e: any) => {
									setContent(String(e.target.value));
								}}
							/>
						</Form.Item>

						{querySiteId ? [] : (
							<Form.Item label='Site/Project*'>
								<SiteSelect
									siteOptions={siteOptions}
									style={{width: '100%'}}
									placeholder='Select a Site'
									onChange={value => {
										setSiteId(parseNum(String(value)));
									}}
								/>
							</Form.Item>
						)}

						<Form.Item label='Assign this Query to*'>
							<Select
								id={'assignedTo'}
								showSearch={true}
								style={{width: '100%'}}
								placeholder='Select For Whom'
								optionFilterProp='children'
								onChange={value => {
									setAssignedTo(parseInt(String(value), 10));
								}}
							>
								{assignOptions.map((option: FilterOptionItem, ix: number) =>
									<Select.Option key={ix} value={option.value}>{option.label}</Select.Option>,
								)}
								<Select.Option key={-1} value={-1}>{'I don\'t know whom to assign'}</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item label='Attachments (if any)'>
							<FileUpload
								prefix={'query'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={((fileList, filePrefixKeys) => {
									setFileList(fileList);
									setFilePrefixKeys(filePrefixKeys);
								})}
							/>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button id={'submit'} type='primary' onClick={onSubmitClicked}>Submit</Button>
						</Form.Item>
					</Form>
				</Col>

				<Col span={24}>
					<Card size='small' title={'Important note'}>
						<ul>
							<li>
								- <a
									href={'https://docs.google.com/document/d/1OH8_tzF7D1ixRC28L45_y3N2tQSYIRgID6gjqL1gyEQ/edit?usp=sharing'}
									target='_blank' rel='noreferrer'
								>
									Points to know about queries.
								</a>
							</li>
							<li>
								{`- If you want to edit or delete the raised query or assigned it to a different person,
								mark the raised query as 'Closed' and raise a fresh query.`}
							</li>
						</ul>
					</Card>
				</Col>
			</Row>
		</Spin>
	);
};
