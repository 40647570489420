import React from 'react';
import {
	ModuleName,
	ModulePermission,
	type Site,
	type User,
	WorkOrderStatus,
	type WorkOrder,
} from 'app/models';
import {
	formatDateFunction,
	getPermissionSites,
	toSubstring,
	toTitleCase,
	parseNum,
} from 'app/helpers';
import {Link} from 'react-router-dom';
import {uiPaths} from 'app/constants';
import {Col, Empty, Row, Spin, Table, Space, Button, Popconfirm, Modal, Input} from 'antd';
import type Types from 'MyTypes';
import {
	count,
	get,
	onPaginationChange,
	setFilterValue,
	approveAll,
	cancelAll,
} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {getQueryDateRange} from 'app/query_helpers';
import {
	FilterFormType,
	type FilterOptionItem,
	type UiFilter,
} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from '../Common/Filter/FilterView';
import {PaginationView} from '../Common/Pagination/PaginationView';

type Props = {
	toBeApproved?: boolean;
};

export const ViewAllWorkOrder: React.FC<Props> = ({toBeApproved}) => {
	const dispatch = useDispatch();
	const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
	const [cancelReason, setCancelReason] = React.useState<string>('');
	const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false);
	const [approveComment, setApproveComment] = React.useState<string>('');
	const [showApproveModal, setShowApproveModal] = React.useState<boolean>(false);

	const {
		loading,
		byIds,
		allIds,
		totalCount,
		currentPos,
		perPageSize,
		filterValue,
		dataUpdated,
	} = useSelector((state: Types.RootState) => state.workOrder);
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	console.log(filterValue);
	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.WORK_ORDER,
		ModulePermission.APPROVE,
		allSites,
	);

	React.useEffect(() => {
		if (filterValue?.where) {
			filterData(filterValue);
		} else {
			filterData();
		}
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			if (filterValue) {
				filterData(filterValue, currentPos, perPageSize);
			} else {
				filterData();
			}
		}
	}, [dataUpdated]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
			order: ['createdAt DESC'],
			include: [
				{relation: 'contractorForemen'},
				{relation: 'paymentMilestones'},
				{relation: 'debits'},
			],
		};

		if (toBeApproved) {
			filter.where.nextApprovedBy = authUser?.id;
		} else if (!f.siteId) {
			filter.where.siteId = {inq: sites.map(({id}) => id)};
		}

		if (f.status) {
			filter.where.status = f.status;
		}

		if (f.siteId) {
			filter.where.siteId = parseNum(String(f.siteId));
		}

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(String(f.date1), String(f.date2), 'createdAt');
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		console.log('selectedRowKeys changed: ', newSelectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleApproveAllClick = () => {
		setShowApproveModal(true);
	};

	const handleApproveAllOrderOk = () => {
		setShowApproveModal(false);
		const ids = selectedRowKeys.map(key => parseNum(key));
		if (ids?.length) {
			dispatch(approveAll(ids, {approveComment}));
		}
	};

	const handleApproveAllOrderCancel = () => {
		setShowApproveModal(false);
	};

	const handleRejectAllClick = () => {
		setShowCancelModal(true);
	};

	const handleRejectAllOrderOk = () => {
		setShowCancelModal(false);
		const ids = selectedRowKeys.map(key => parseNum(key));
		if (ids?.length) {
			dispatch(cancelAll(ids, {cancelReason}));
		}
	};

	const handleRejectAllOrderCancel = () => {
		setShowCancelModal(false);
	};

	const orders: WorkOrder[] = allIds.map((id: number) => ({
		...{key: String(id)},
		...byIds[id],
	}));
	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));
	const statusOptions = Object.values(WorkOrderStatus).map(status => ({
		value: String(status),
		label: toTitleCase(String(status)) ?? '',
	}));

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			items: [],
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
		},
	];

	if (!toBeApproved) {
		uiFilters.push({
			filterKey: 'status',
			items: statusOptions,
			formType: FilterFormType.SELECT,
			label: 'Status',
			defaultValue: filterValue?.status,
		});
	}

	if (siteOptions.length > 1) {
		uiFilters.push({
			filterKey: 'siteId',
			items: siteOptions,
			formType: FilterFormType.SITE_SELECT,
			formWidth: 240,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue?.siteId,
		});
	}

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row>
				<Col span={24}>
					<FilterView uiFilters={uiFilters} onSubmit={(f: FilterDataType) => {
						filterData(f);
					}} />
					<br />
				</Col>
				<Col span={12} className='mb-10'>
					{selectedRowKeys.length ? (
						<Space>
							<Popconfirm
								title={'Are you sure you want to mark approve to all!'}
								onConfirm={() => {
									handleApproveAllClick();
								}}
							>
								<Button type={'primary'}>Approve All</Button>
							</Popconfirm>
							<Popconfirm
								title={'Are you sure you want to mark cancel to all!'}
								onConfirm={() => {
									handleRejectAllClick();
								}}
							>
								<Button type={'primary'}>Cancel All</Button>
							</Popconfirm>
						</Space>
					) : (
						[]
					)}
				</Col>
			</Row>

			{totalCount ? (
				<Row>
					<Col span={24}>
						<PaginationView
							isFunctional={true}
							total={totalCount}
							currentPos={currentPos ?? 1}
							perPageSize={perPageSize ?? 10}
							filterValue={filterValue}
							filterData={filterData}
							onPaginationChange={onPaginationChange}
						/>
						<Table
							size={'small'}
							dataSource={orders}
							pagination={false}
							rowSelection={
								toBeApproved
									? {
										selectedRowKeys,
										onChange: onSelectChange,
									}
									: undefined
							}
						>
							<Table.Column
								title='Raise At'
								dataIndex='createdAt'
								key='createdAt'
								render={(createdAt: string | undefined) =>
									formatDateFunction(createdAt ?? '')
								}
							/>
							<Table.Column
								title='Site'
								dataIndex='site'
								key='site'
								render={(site: Site | undefined) => site?.name ?? ''}
							/>
							<Table.Column
								title='Title'
								dataIndex='title'
								key='title'
								render={(title, record: WorkOrder) => (
									<Link
										to={{
											pathname: `/${uiPaths.workOrderDetail.replace(
												':orderId',
												String(record.id),
											)}`,
										}}
									>
										{toSubstring(String(title), 80, '...')}
									</Link>
								)}
							/>
							<Table.Column
								title='Contractor'
								dataIndex='contractor'
								key='contractor'
								render={(contractor: User | undefined) =>
									contractor?.name ?? ''
								}
							/>
							<Table.Column
								title='Status'
								dataIndex='status'
								key='status'
								render={status => toTitleCase(String(status), '_')}
							/>
						</Table>
					</Col>
				</Row>
			) : (
				<Row>
					<Col span={24} style={{textAlign: 'center'}}>
						<Empty />
					</Col>
				</Row>
			)}

			<Modal
				title='Cancel Work Order'
				open={showCancelModal}
				onOk={handleRejectAllOrderOk}
				onCancel={handleRejectAllOrderCancel}
			>
				<Space direction={'vertical'}>
					<label>Please prove a reason to cancel this work order</label>
					<Input.TextArea
						placeholder={'Enter Cancel Reason'}
						defaultValue={cancelReason ?? ''}
						value={cancelReason ?? ''}
						onChange={(e: any) => {
							setCancelReason(String(e.target.value));
						}}
					/>
				</Space>
			</Modal>

			<Modal
				title='Approve Work Order'
				open={showApproveModal}
				onOk={handleApproveAllOrderOk}
				onCancel={handleApproveAllOrderCancel}
			>
				<Space direction={'vertical'}>
					<label>Please enter a comment (optional)</label>
					<Input.TextArea
						placeholder={'Enter a comment'}
						defaultValue={approveComment ?? ''}
						value={approveComment ?? ''}
						onChange={(e: any) => {
							setApproveComment(String(e.target.value));
						}}
					/>
				</Space>
			</Modal>
		</Spin>
	);
};
