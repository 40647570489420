import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {
	type ProjectProcessMaster,
	ProjectProcessType,
} from 'app/components/ProjectProcessMaster/project-process-master';
import {Link} from 'react-router-dom';
import {
	type ProjectProcessSchedule,
} from 'app/components/ProjectProcessSchedule/project-process-schedule';
import {
	Col,
	Row,
	Spin,
	Table,
	Typography,
} from 'antd';
import {ModuleName, ModulePermission} from 'app/models';
import {customerCareWarrantyPdfLink, siteResourceDirectorySheetLink, uiPaths} from 'app/constants';
import {getPermissionSites, getS3Url} from 'app/helpers';
import {get} from '../ProjectProcessSchedule/actions';
import {ProjectFileType} from '../Site/project-file';
import {getProjectFiles} from '../Site/actions';

export const ViewClientWarrantyDocument: React.FC = () => {
	const dispatch = useDispatch();
	const {
		allIds,
		byIds,
		loading,
	} = useSelector((state: Types.RootState) => state.projectProcessSchedule);
	const {allSites, byModule: permissions} = useSelector((state: Types.RootState) => state.userPermission);
	const {projectFiles} = useSelector((state: Types.RootState) => state.site);
	const sites = getPermissionSites(permissions, ModuleName.WARRANTY_DOCUMENTS, ModulePermission.READ, allSites);
	const allItems = allIds?.length ? allIds.map(id => byIds[id]) : [];
	const dataSource = allItems.filter(item => item.projectProcessMaster?.parentId && item.projectProcessType === ProjectProcessType.WARRANTY && Boolean(item.warrantyDocuments?.length));

	React.useEffect(() => {
		console.log('ViewClientWarrantyDocument component');
		if (sites?.length) {
			dispatch(get({
				where: {
					siteId: sites[0].id,
					projectProcessType: ProjectProcessType.WARRANTY,
				},
			}));

			dispatch(getProjectFiles(sites[0].id, ProjectFileType.FINAL_HANDOVER_LETTER));
		}
	}, []);

	return (
		<DefaultLayout currentPath={uiPaths.clientWarrantyDocuments}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
						Warranty Documents
						</Typography.Title>
					</Col>
					<Col span={24}>
						<br/>
						<a
							href={customerCareWarrantyPdfLink}
							target='_blank'
							rel='noreferrer'
						>
							<b>CLICK HERE TO HAVE VENDOR INFORMATION</b>
						</a>
						<br/><br/>
					</Col>
					{projectFiles?.length ? (
						<Col span={24}>
							<br/>
							<a
								href={getS3Url(projectFiles[0]?.files?.split(',')?.[0] ?? '')}
								target='_blank'
								rel='noreferrer'
							>
								<b>CLICK HERE TO HAVE FINAL HANDOVER LETTER </b>
							</a>
							<br/><br/>
						</Col>
					) : []}
				</Row>
				<Table
					size={'small'}
					bordered={true}
					dataSource={dataSource}
					pagination={false}
				>
					<Table.Column
						dataIndex='projectProcessMaster'
						key='projectProcessMaster'
						render={(
							projectProcessMaster: ProjectProcessMaster,
							schedule: ProjectProcessSchedule,
						) => (
							<Link
								to={{
									pathname: `/${uiPaths.warrantyDocumentDetail.replace(
										':id',
										String(schedule.id),
									)}`,
								}}
							>
								{projectProcessMaster?.processName ?? 'N/A'}
							</Link>
						)}
					/>
				</Table>
			</Spin>
		</DefaultLayout>
	);
};
