import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Row} from 'antd';
import {DefaultLayout} from '../Layout/DefaultLayout';
import type Types from 'MyTypes';
import {type User} from 'app/models';
import {get as getSites} from '../Site/actions';
import {FilterView} from 'app/components/Common/Filter/FilterView';
import {
	FilterFormType,
	type UiFilter,
} from 'app/models/ui-filter';
import {
	type DataSourceType,
	type ReportColumnType,
	ViewHierarchyReport,
	populateDataSourceTree,
	emptyNode,
	createUserTree,
} from './ViewHierarchyReport';
import {
	mutateTree,
	getKey,
} from 'app/services/report-service';
import {
	type LabourAttendance,
	LabourType,
	SiteStatus,
} from 'app/models';
import {
	get,
	setDailyLabourReportFilterValue,
	initPage,
} from '../LabourAttendance/actions';
import {getQueryDateRangeForDays, getQueryDateRange} from 'app/query_helpers';
import {formatDateFunction, parseNum, toTitleCase} from 'app/helpers';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const ViewDailyLabourReport: React.FC = () => {
	const dispatch = useDispatch();
	const {byIds, allIds, loading, filterValue}
    = useSelector((state: Types.RootState) => state.labourAttendance);
	const {users} = useSelector((state: Types.RootState) => state.summary);
	const today = new Date(new Date().setHours(0, 0, 0, 0));
	const {sites: allSites} = useSelector(
		(state: Types.RootState) => state.site,
	);

	const reports: LabourAttendance[] = allIds.map(id => byIds[id]);
	const mapOfRecords = reports.reduce <Record<string, Record<number, Record<string, LabourAttendance>>>>(
		(record, item) => {
			const date = formatDateFunction(item.reportDate, false);
			if (!record[date]) {
				record[date] = {};
			}

			if (!record[date][item.siteId]) {
				record[date][item.siteId] = {};
			}

			record[date][item.siteId][item.labourType] = item;
			return record;
		},
		{},
	);

	const reportColumns: ReportColumnType[] = Object.keys(mapOfRecords)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
		.map(d => ({
			title: d,
			key: getKey(d),
			children: [
				{title: 'Required Count', key: getKey(d, '-requiredCount'), width: 100},
				{title: 'Actual Count', key: getKey(d, '-actualCount'), width: 100},
			],
		}));

	const filterData = (f: FilterDataType = {}) => {
		const filter: any = {
			where: {},
			offset: 0,
			limit: 300000,
			order: ['createdAt ASC'],
		};

		if (f.date1 && f.date2) {
			filter.where.and = getQueryDateRange(f.date1 as string, f.date2 as string, 'reportDate');
		}

		if (!f.date1 || !f.date2) {
			filter.where.and = getQueryDateRangeForDays(
				today.toString(),
				0,
				6,
				'reportDate',
			);
			f.date1 = new Date(new Date().setDate(new Date().getDate() - 6))
				.toISOString()
				.split('T')[0];
			f.date2 = new Date().toISOString().split('T')[0];
		}

		dispatch(setDailyLabourReportFilterValue(f));
		dispatch(get(filter));
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
		console.log('ViewDailyLabourReport.componentDidMount');
		dispatch(initPage());
		if (filterValue) {
			filterData(filterValue);
		}

		dispatch(
			getSites({
				where: {
					status: {inq: [SiteStatus.UNDER_CONSTRUCTION, SiteStatus.UNDER_DESIGN]},
				},
			}),
		);
	}, []);

	const createDataSourceTree = (node: DataSourceType) => {
		const nodeKeys = node.key.split('-');
		if (nodeKeys[0] === 'user') {
			// The user is site Incharge
			const siteChildren = allSites.filter(site => site.siteInchargeId === parseNum(nodeKeys[1])).map(site => {
				const x: DataSourceType = {
					key: 'site-' + String(site.id),
					name: site?.name ?? '',
					records: {},
					children: Object.values(LabourType).map(val => {
						const y: DataSourceType = {
							key: 'type-' + val,
							name: toTitleCase(val) ?? '',
							records: Object.keys(mapOfRecords).reduce<Record<string, number>>((record, d) => {
								record[getKey(d, '-requiredCount')] = mapOfRecords[d]?.[site.id]?.[val]?.requiredCount ?? 0;
								record[getKey(d, '-actualCount')] = mapOfRecords[d]?.[site.id]?.[val]?.actualCount ?? 0;
								return record;
							}, {}),
						};
						return y;
					}),
				};
				return x;
			});

			if (siteChildren?.length) {
				console.log(`${node.name} ${siteChildren?.length}`);
				if (node.children?.length) {
					node.children.push(...siteChildren);
				} else {
					node.children = siteChildren;
				}
			}

			if (node.children?.length) {
				node.children.forEach(childNode =>
					createDataSourceTree(childNode),
				);
			}
		}

		return node;
	};

	const roots: User[] = mutateTree(
		users,
		allSites.map(({siteInchargeId}) => siteInchargeId ?? 0),
	);

	const userTree = roots.map(user =>
		createUserTree({...emptyNode}, user),
	);

	const initDataSource = userTree.map(userNode =>
		createDataSourceTree(userNode),
	);

	const dataSource = initDataSource.map(node =>
		populateDataSourceTree(node),
	);

	const uiFilters: UiFilter[] = [
		{
			filterKey: 'date',
			formType: FilterFormType.DATE_RANGE,
			label: 'Date',
			defaultValue: [filterValue?.date1 as string, filterValue?.date2 as string],
			disabledDateDays: 31,
		},
	];

	return (
		<DefaultLayout>
			<>
				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={f => {
								filterData(f);
							}}
						/>
						<br />
					</Col>
				</Row>
				<ViewHierarchyReport
					title='Labour Report'
					loading={loading}
					columns={reportColumns}
					dataSource={dataSource}
					reportDescription={`
				    This report presents the required labour count and actual labour count on site..The data in the report is captured on the daily basis from the data entered by the site incharge in the Labour Attendance Module.If there is any increase in the number of Required or Actual Labour which means more number of labour is required or present in actual on site as compared to the previous week.
				   `}
					reportLogic={`
				  Based upon the number of actual labour and required labour are captured in the Labour Attendance Module To read the report, you can expand the “+” sign in front of the Reporting Manager name to see the list of employees reporting to him, site under the particular employee and then the labour category also." If you have any query related to this report or a suggestion to improvise the report. Please Contact ruchika.singh@prithu.in
				`}
				/>
			</>
		</DefaultLayout>
	);
};
