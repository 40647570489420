import React, {useState} from 'react';
import {
	Affix,
	Avatar,
	FloatButton,
	Badge,
	Button,
	Col,
	Divider,
	Drawer,
	Layout,
	Menu,
	Row,
	Space,
	Tooltip,
} from 'antd';
import './DefaultLayout.less';
import logo from '../../assets/img/logo.png';
import {
	PlusCircleOutlined,
	MinusCircleOutlined,
	BellOutlined,
	HomeOutlined,
	MenuOutlined,
	UserOutlined,
} from '@ant-design/icons';
import {retoolIds, uiPaths} from 'app/constants';
import {Redirect} from 'react-router-dom';
import {isMobile} from 'app/helpers';
import type Types from 'MyTypes';
import {useDispatch, useSelector} from 'react-redux';
import {ModuleName, type User, UserType} from 'app/models';
import {UserRole} from 'app/models/enums/user-role.enum';
import {logout} from '../Common/auth-actions';

type Props = {
	currentPath?: string;
	children: React.ReactNode;
};

export const DefaultLayout: React.FC<Props> = ({children, currentPath}) => {
	const dispatch = useDispatch();
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const {modulesHasPermissions, canApprove} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);

	const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [redirectPath, setRedirectPath] = useState<string>('');

	const {SubMenu} = Menu;

	const signedIn
    = localStorage.getItem('appToken')?.length;

	if (!signedIn) {
		return <Redirect to='/login' />;
	}

	if (redirectPath && (!currentPath || currentPath !== redirectPath)) {
		return (
			<Redirect
				to={`/${redirectPath === uiPaths.home ? '' : redirectPath}`}
			/>
		);
	}

	const handleDrawerMenuClick = (e: any) => {
		if (e.key === 'logout') {
			// eslint-disable-next-line no-alert
			if (window.confirm('Are you sure you want to logout?')) {
				dispatch(logout());
				setRedirectPath(uiPaths.login);
			}
		} else {
			setRedirectPath(String(e.key));
		}

		setDrawerVisible(false);
	};

	const hasPermission = (module: ModuleName) => Boolean(modulesHasPermissions?.includes(module));

	// eslint-disable-next-line complexity
	const getSideMenu = (authUser?: User) => (
		<Menu
			theme='light'
			mode='inline'
			style={{width: '100%'}}
			selectedKeys={currentPath ? [currentPath] : []}
			onClick={handleDrawerMenuClick}
			expandIcon={prop =>
				prop.isOpen ? <MinusCircleOutlined /> : <PlusCircleOutlined />
			}
		>
			{isMobile && (
				<Menu.Item id={uiPaths.home} key={uiPaths.home}>
					<HomeOutlined />
					<span>Home</span>
				</Menu.Item>
			)}

			{authUser?.roles === UserRole.TECHINICAL_HEAD ? (
				<>
					<Menu.Item id={uiPaths.dummi} key={uiPaths.dummi}>
						<span>DUMMI</span>
					</Menu.Item>
				</>
			) : (
				[]
			)}

			{authUser?.isAdmin ? (
				<>
					<Menu.Item id={uiPaths.site} key={uiPaths.site}>
						<span>Projects/Sites</span>
					</Menu.Item>
					<Menu.Item id={uiPaths.user} key={uiPaths.user}>
						<span>Users</span>
					</Menu.Item>
					<Menu.Item
						id={uiPaths.allPermissions}
						key={uiPaths.allPermissions}
					>
						<span>User Permissions</span>
					</Menu.Item>
					<Menu.Item
						id={uiPaths.allCategories}
						key={uiPaths.allCategories}
					>
						<span>Category Master</span>
					</Menu.Item>
					<Menu.Item
						id={uiPaths.viewEmailTemplates}
						key={uiPaths.viewEmailTemplates}
					>
						<Tooltip title={'Email Templates'}> {'Email Templates'}</Tooltip>
					</Menu.Item>
					<Menu.Item id={uiPaths.changeLog} key={uiPaths.changeLog}>
						<span>Change Log</span>
					</Menu.Item>
					<Menu.Item id={uiPaths.activityTemplates} key={uiPaths.activityTemplates}>
						<span>Activity Template</span>
					</Menu.Item>
					<Menu.Item id={uiPaths.allMaterialPurchaseOrder} key={uiPaths.allMaterialPurchaseOrder}>
						<span>Material Purchase Order</span>
					</Menu.Item>
					<Menu.Item id={uiPaths.allMaterialMasters} key={uiPaths.allMaterialMasters}>
						<span>Material Masters</span>
					</Menu.Item>
				</>
			) : (
				[]
			)}

			{canApprove ? (
				<SubMenu key='approval' title='To Approve'>
					<Menu.Item
						id={uiPaths.checklistResponseApproval}
						key={uiPaths.checklistResponseApproval}
					>
              Checklist Response
					</Menu.Item>
					<Menu.Item
						id={uiPaths.allWorkOrderBill}
						key={uiPaths.allWorkOrderBill}
					>
              Contractor Bill
					</Menu.Item>
					<Menu.Item
						id={uiPaths.allWorkOrder}
						key={uiPaths.allWorkOrder}
					>
              Work Order
					</Menu.Item>
					<Menu.Item
						id={uiPaths.allMaterialQuery}
						key={uiPaths.allMaterialQuery}
					>
              Material Query
					</Menu.Item>
					<Menu.Item
						id={uiPaths.approvalWorkOrderQuery}
						key={uiPaths.approvalWorkOrderQuery}
					>
              Work Order Query
					</Menu.Item>
					<Menu.Item
						id={uiPaths.paymentMilestoneApproval}
						key={uiPaths.paymentMilestoneApproval}
					>
              Payment Milestone
					</Menu.Item>
				</SubMenu>
			) : (
				[]
			)}

			{authUser?.userType === UserType.CLIENT ? [] : (
				<SubMenu key='reports' title='Reports'>
					<SubMenu key='construction schedule report' title='Construction Schedule'>
						<Menu.Item
							id={uiPaths.projectScheduleDelayReport}
							key={uiPaths.projectScheduleDelayReport}
						>
							<Tooltip title={'Project Schedule Delay Report'}>
								{' '}
								{'Project Schedule Delay Report'}
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							id={uiPaths.dailyProjectScheduleDelayReport}
							key={uiPaths.dailyProjectScheduleDelayReport}
						>
							<Tooltip title={'Daily Project Schedule Delay Report'}>
								{' '}
								{'Daily Project Schedule Delay Report'}
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							id={uiPaths.dailyProjectScheduleDelayByStatusReport}
							key={uiPaths.dailyProjectScheduleDelayByStatusReport}
						>
							<Tooltip title={'Delay by Status Daily Report'}>
								{' '}
								{'Delay by Status Daily Report'}
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							id={uiPaths.projectScheduleActivityDelayReport}
							key={uiPaths.projectScheduleActivityDelayReport}
						>
							<Tooltip title={'Started Activities Delay Report'}>
								{' '}
								{'Started Activities Delay Report'}
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							id={uiPaths.projectScheduleNotCompletedDelayReport}
							key={uiPaths.projectScheduleNotCompletedDelayReport}
						>
							<Tooltip title={'Not Completed Delay Report'}>
								{' '}
								{'Not Completed Delay Report'}
							</Tooltip>
						</Menu.Item>
						<Menu.Item
							id={uiPaths.constructionSchedulePhotoReport}
							key={uiPaths.constructionSchedulePhotoReport}
						>
							<Tooltip title={'Daily Photo Report'}>
								{' '}
								{'Daily Photo Report'}
							</Tooltip>
						</Menu.Item>
					</SubMenu>
					<SubMenu key='approval schedule' title='Approval Schedule'>
						<Menu.Item
							id={uiPaths.approvalScheduleActivityDelayReport}
							key={uiPaths.approvalScheduleActivityDelayReport}
						>
							<Tooltip title={'Started Activities Delay Report'}>
								{' '}
								{'Started Activities Delay Report'}
							</Tooltip>
						</Menu.Item>
					</SubMenu>
					<SubMenu key='petty cash' title='Petty Cash'>
						<Menu.Item
							id={uiPaths.monthlyPettyCashReport}
							key={uiPaths.monthlyPettyCashReport}
						>
							<Tooltip title={'Laisioning Expense Monthly Report'}>
								{' '}
								{'Laisioning Expense Monthly Report'}
							</Tooltip>
						</Menu.Item>
					</SubMenu>
					<SubMenu key='work-order' title='Work Order'>
						<Menu.Item
							id={uiPaths.paymentMilestoneReport}
							key={uiPaths.paymentMilestoneReport}
						>
				Payment Milestone Report
						</Menu.Item>
						<Menu.Item
							id={uiPaths.workOrderBalanceReport}
							key={uiPaths.workOrderBalanceReport}
						>
							<Tooltip title={'Balance Payment Report'}>
								{' '}
								{'Balance Payment Report'}
							</Tooltip>
						</Menu.Item>
					</SubMenu>
					<SubMenu key='drawing-schedule' title='Drawing Schedule'>
						<Menu.Item
							id={uiPaths.drawingScheduleActivityReport}
							key={uiPaths.drawingScheduleActivityReport}
						>
					Drawing Schedule Uploads Report
						</Menu.Item>
						<Menu.Item
							id={uiPaths.dailyDrawingScheduleActivityReport}
							key={uiPaths.dailyDrawingScheduleActivityReport}
						>
					Daily Drawing Schedule Uploads Report
						</Menu.Item>
					</SubMenu>
					<Menu.Item
						id={uiPaths.projectQueryReport}
						key={uiPaths.projectQueryReport}
					>
              Project Query Report
					</Menu.Item>
					<Menu.Item
						id={uiPaths.corporateQueryReport}
						key={uiPaths.corporateQueryReport}
					>
              Corporate Query Report
					</Menu.Item>
					<Menu.Item
						id={uiPaths.dailyLabourReport}
						key={uiPaths.dailyLabourReport}
					>
              Daily Labour Report
					</Menu.Item>
					<Menu.Item
						id={uiPaths.electricityConsumptionReport}
						key={uiPaths.electricityConsumptionReport}
					>
              Electricity Consumption Report
					</Menu.Item>
					<Menu.Item
						id={uiPaths.designProgressTrackerReport}
						key={uiPaths.designProgressTrackerReport}
					>
              Design Progress Tracker Report
					</Menu.Item>
					{authUser?.isAdmin ? (
						<Menu.Item
							id={uiPaths.retool.replace(
								':id',
								retoolIds.pendingRoleAssignmentReport,
							)}
							key={uiPaths.retool.replace(
								':id',
								retoolIds.pendingRoleAssignmentReport,
							)}
						>
							<Tooltip title={'Pending Role Assignment Report'}>
								{' '}
								{'Pending Role Assignment Report'}
							</Tooltip>
						</Menu.Item>
					) : (
						[]
					)}
					<SubMenu key='checklist' title='Checklist'>
						<SubMenu key='construction schedule' title='Construction Schedule'>
							<Menu.Item
								id={uiPaths.checklistResponseReport}
								key={uiPaths.checklistResponseReport}
							>
								<Tooltip title={'Checklist Response Report'}>
									{' '}
									{'Checklist Response Report'}
								</Tooltip>
							</Menu.Item>
							<Menu.Item
								id={uiPaths.checklistApprovalReport}
								key={uiPaths.checklistApprovalReport}
							>
								<Tooltip title={'Checklist Approval Report'}>
									{' '}
									{'Checklist Approval Report'}
								</Tooltip>
							</Menu.Item>
						</SubMenu>
						<SubMenu key='drawing schedule' title='Drawing Schedule'>
							<Menu.Item
								id={uiPaths.drawingScheduleChecklistResponseReport}
								key={uiPaths.drawingScheduleChecklistResponseReport}
							>
								<Tooltip title={'Checklist Response Report'}>
									{' '}
									{'Checklist Response Report'}
								</Tooltip>
							</Menu.Item>
						</SubMenu>
					</SubMenu>
					<SubMenu key='client queries' title='Client Queries'>
						<Menu.Item
							id={uiPaths.oragnisationalClientQueryReport}
							key={uiPaths.oragnisationalClientQueryReport}
						>
                For Internal Users
						</Menu.Item>
						<Menu.Item
							id={uiPaths.clientQueryOverdueReport}
							key={uiPaths.clientQueryOverdueReport}
						>
                Overdue Report
						</Menu.Item>
						<Menu.Item
							id={uiPaths.clientQueryReportForClients}
							key={uiPaths.clientQueryReportForClients}
						>
                For Clients
						</Menu.Item>
						<Menu.Item
							id={uiPaths.clientQueryDeviceUsageReport}
							key={uiPaths.clientQueryDeviceUsageReport}
						>
                Device Usage
						</Menu.Item>
					</SubMenu>
					<SubMenu key='warranty-queries' title='Warranty Queries'>
						<Menu.Item
							id={uiPaths.weeklyWarrantyQueryReport}
							key={uiPaths.weeklyWarrantyQueryReport}
						>
							Weekly Report
						</Menu.Item>
					</SubMenu>
				</SubMenu>
			)}

			{authUser?.userType === UserType.EMPLOYEE ? (
				<>
					<Menu.Item
						id={uiPaths.corporateQuery}
						key={uiPaths.corporateQuery}
					>
						<span>Corporate Queries</span>
					</Menu.Item>
					<Menu.Item id={uiPaths.query} key={uiPaths.query}>
						<span>Project Queries</span>
					</Menu.Item>
				</>
			) : (
				[]
			)}

			{authUser?.userType === UserType.CLIENT
        && hasPermission(ModuleName.CLIENT_QUERY) ? (
					<Menu.Item id={uiPaths.clientQuery} key={uiPaths.clientQuery}>
						<span>Queries</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.CLIENT_QUERY) ? (
					<Menu.Item id={uiPaths.clientQuery} key={uiPaths.clientQuery}>
						<span>Client Queries</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.CLIENT
        && hasPermission(ModuleName.WARRANTY_QUERY) ? (
					<Menu.Item id={uiPaths.warrantyClientQuery} key={uiPaths.warrantyClientQuery}>
						<span>Warranty Queries</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
			&& hasPermission(ModuleName.WARRANTY_QUERY) ? (
					<Menu.Item id={uiPaths.warrantyQuery} key={uiPaths.warrantyQuery}>
						<span>Warranty Queries</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.EXPENSE_REPORT) ? (
					<Menu.Item id={uiPaths.pettyCash} key={uiPaths.pettyCash}>
						<span>{'Petty Cash'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.MATERIAL_QUERY) ? (
					<Menu.Item id={uiPaths.materialQuery} key={uiPaths.materialQuery}>
						<span>{'Material Queries'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.WORK_ORDER_QUERY) ? (
					<Menu.Item id={uiPaths.workOrderQuery} key={uiPaths.workOrderQuery}>
						<span>Work Order Queries</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.WORK_ORDER) ? (
					<Menu.Item id={uiPaths.workOrder} key={uiPaths.workOrder}>
						<span>{'Work Order'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.ELECTRICITY_CONSUMPTION) ? (
					<Menu.Item id={uiPaths.electricityConsumption} key={uiPaths.electricityConsumption}>
						<span>{'Electricity Consumption'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.WORK_ORDER_BILL) ? (
					<Menu.Item
						id={uiPaths.workOrderBill}
						key={uiPaths.workOrderBill}
					>
						<span>{'Contractor\'s Bill'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{/* {hasPermission(ModuleName.PROJECT_HANDOVER_SCHEDULE) ? (
          <Menu.Item
            id={UI_PATHS.VIEW_PROJECT_HANDOVER_SCHEDULE}
            key={UI_PATHS.VIEW_PROJECT_HANDOVER_SCHEDULE}
          >
            <Tooltip title={'Project Handover Schedule'}>
              {' '}
              {'Project Handover Schedule'}
            </Tooltip>
          </Menu.Item>
        ) : (
          []
        )} */}

			{/* {authUser?.userType === UserType.EMPLOYEE &&
          hasPermission(ModuleName.PROJECT_BUDGET_QUANTITIES) ? (
          <Menu.Item
            id={UI_PATHS.PROJECT_BUDGET_QUANTITIES}
            key={UI_PATHS.PROJECT_BUDGET_QUANTITIES}
          >
            <Tooltip title={'Project Budget Quantity'}>
              {' '}
              {'Project Budget Quantity'}
            </Tooltip>
          </Menu.Item>
        ) : (
          []
        )} */}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.DRAWING_SCHEDULE) ? (
					<Menu.Item
						id={uiPaths.drawingProcessSchedule}
						key={uiPaths.drawingProcessSchedule}
					>
						<span>{'Drawing Schedule'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.WARRANTY_DOCUMENTS) ? (
					<Menu.Item
						id={uiPaths.warrantyDocuments}
						key={uiPaths.warrantyDocuments}
					>
						<span>{'Warranty Documents'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.CLIENT
			&& hasPermission(ModuleName.WARRANTY_DOCUMENTS) ? (
					<Menu.Item
						id={uiPaths.clientWarrantyDocuments}
						key={uiPaths.clientWarrantyDocuments}
					>
						<span>{'Warranty Documents'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.CLIENT
				&& hasPermission(ModuleName.WARRANTY_PERIODS) ? (
					<Menu.Item
						id={uiPaths.clientWarrantyPeriods}
						key={uiPaths.clientWarrantyPeriods}
					>
						<span>{'Warranty Periods'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
				&& hasPermission(ModuleName.WARRANTY_PERIODS) ? (
					<Menu.Item
						id={uiPaths.allWarrantyPeriods}
						key={uiPaths.allWarrantyPeriods}
					>
						<span>{'Warranty Periods'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.CLIENT
        && hasPermission(ModuleName.DRAWING_SCHEDULE) ? (
					<Menu.Item
						id={uiPaths.clientDrawingSchedule}
						key={uiPaths.clientDrawingSchedule}
					>
						<span>{'Drawing Update'}</span>
					</Menu.Item>
				) : (
					[]
				)}

			{hasPermission(ModuleName.CONSTRUCTION_SCHEDULE) ? (
				<Menu.Item
					id={uiPaths.constructionSchedule}
					key={uiPaths.constructionSchedule}
				>
					{authUser?.userType === UserType.CLIENT ? (
						<Tooltip title={'Construction Update'}>
							{'Construction Update'}
						</Tooltip>
					) : (
						<Tooltip title={'Construction Schedule'}>
							{'Construction Schedule'}
						</Tooltip>
					)}
				</Menu.Item>
			) : (
				[]
			)}

			{hasPermission(ModuleName.APPROVAL_SCHEDULE) ? (
				<Menu.Item
					id={uiPaths.approvalSchedule}
					key={uiPaths.approvalSchedule}
				>
					{authUser?.userType === UserType.CLIENT ? (
						<Tooltip title={'Approval Update'}> {'Approval Update'}</Tooltip>
					) : (
						<Tooltip title={'Approval Schedule'}>
							{'Approval Schedule'}
						</Tooltip>
					)}
				</Menu.Item>
			) : (
				[]
			)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.SCHEDULE_MANUAL_ENTRY) ? (
					<Menu.Item
						id={uiPaths.scheduleManualEntry}
						key={uiPaths.scheduleManualEntry}
					>
						<span>Schedule Manual Entry</span>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.PROJECT_PROCESS_MASTER) ? (
					<Menu.Item
						id={uiPaths.projectProcessMaster}
						key={uiPaths.projectProcessMaster}
					>
						<Tooltip title={'Project Schedule Tab Masters'}>
							{'Project Schedule Tab Masters'}
						</Tooltip>
					</Menu.Item>
				) : (
					[]
				)}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.PROCESS_CHECKLIST) ? (
					<Menu.Item
						id={uiPaths.processChecklist}
						key={uiPaths.processChecklist}
					>
						<Tooltip title={'Process Checklist Masters'}>
							{'Process Checklist Masters'}
						</Tooltip>
					</Menu.Item>
				) : (
					[]
				)}

			{/* {authUser?.userType === UserType.EMPLOYEE &&
          hasPermission(ModuleName.ON_SITE_ATTENDANCE) ? (
          <Menu.Item id={UI_PATHS.ATTENDANCE} key={UI_PATHS.ATTENDANCE}>
            <span>Attendance</span>
          </Menu.Item>
        ) : (
          []
        )} */}

			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.LABOUR_ATTENDANCE) ? (
					<Menu.Item
						id={uiPaths.labourAttendance}
						key={uiPaths.labourAttendance}
					>
						<span>Labour Attendance</span>
					</Menu.Item>
				) : (
					[]
				)}
			{authUser?.userType === UserType.EMPLOYEE
        && hasPermission(ModuleName.PROJECT_ACTIVITY) ? (
					<Menu.Item
						id={uiPaths.projectActivities}
						key={uiPaths.projectActivities}
					>
						<span>Project Activities</span>
					</Menu.Item>
				) : (
					[]
				)}

			{/*  */}

			<Menu.Item
				id={uiPaths.viewHelpAndFeedback}
				key={uiPaths.viewHelpAndFeedback}
			>
				<span>Help and Feedback</span>
			</Menu.Item>

			{isMobile && (
				<Menu.Item id={uiPaths.profile} key={uiPaths.profile}>
					<UserOutlined />
					<span>Profile</span>
				</Menu.Item>
			)}

			{isMobile && (
				<Menu.Item id={uiPaths.notification} key={uiPaths.notification}>
					<Badge count={authUser?.newNotificationCount}>
						<BellOutlined />
					</Badge>
					<span>Notification</span>
				</Menu.Item>
			)}

			<Menu.Item id={uiPaths.logout} key={uiPaths.logout}>
				<span>Logout</span>
			</Menu.Item>
		</Menu>
	);

	return (
		<Layout id={'components-layout-demo-custom-trigger'}>
			<Affix>
				<Layout.Header
					className='site-layout-background shadow-bottom'
					style={{
						padding: '0px 8px',
						background: 'white',
						borderBottom: '1px solid #d9d9d9',
					}}
				>
					<Row>
						<Col flex='100px'>
							<img style={{height: 40}} src={logo} alt={'Prithu Homes'} />
						</Col>
						<Col flex='auto'>
							<Row justify='end'>
								{isMobile ? (
									<Space>
										<Button
											type='default'
											icon={<MenuOutlined />}
											onClick={() => {
												setDrawerVisible(true);
											}}
										>
                      Menu
										</Button>
									</Space>
								) : (
									<Space>
										<Button
											id={'homeMenu'}
											type={
												redirectPath === uiPaths.home ? 'primary' : 'default'
											}
											icon={<HomeOutlined />}
											onClick={() => {
												setRedirectPath(uiPaths.home);
											}}
										>
                      Home
										</Button>
										<Button
											id={'profileMenu'}
											type={
												redirectPath === uiPaths.profile
													? 'primary'
													: 'default'
											}
											icon={<UserOutlined />}
											onClick={() => {
												setRedirectPath(uiPaths.profile);
											}}
										>
                      Profile
										</Button>
										<Button
											id={'notificationMenu'}
											type={
												redirectPath === uiPaths.notification
													? 'primary'
													: 'default'
											}
											icon={
												<Badge count={authUser?.newNotificationCount}>
													<BellOutlined />
												</Badge>
											}
											onClick={() => {
												setRedirectPath(uiPaths.notification);
											}}
										>
                      Notification
										</Button>
									</Space>
								)}
							</Row>
						</Col>
					</Row>
				</Layout.Header>
			</Affix>
			<FloatButton.BackTop />
			<Layout className='site-layout'>
				{!isMobile && (
					<Layout.Sider
						theme='light'
						style={{background: 'white'}}
						collapsible={true}
						collapsed={collapsed}
						onCollapse={collapsed => {
							setCollapsed(collapsed);
						}}
					>
						<Divider style={{paddingTop: '0px'}}>
							<Space wrap={true}>
								<Avatar icon={<UserOutlined />} />
								{authUser && !collapsed ? authUser.name : ''}
							</Space>
						</Divider>
						{getSideMenu(authUser)}
						<br />
						<br />
						<p style={{textAlign: 'center'}}>
              App Version: {localStorage.getItem('appVersionName')}
						</p>
					</Layout.Sider>
				)}
				<Layout.Content
					className='site-layout-background'
					style={{
						margin: '8px',
						padding: 12,
						minHeight: 580,
					}}
				>
					{children}
					<Layout.Footer style={{textAlign: 'center', background: 'white'}}>
            Copyright © {new Date().getFullYear()} Prithu Homes. All Rights Reserved.
					</Layout.Footer>
				</Layout.Content>
			</Layout>
			<Drawer
				title='Menu'
				placement={'right'}
				closable={true}
				onClose={() => {
					setDrawerVisible(false);
				}}
				open={drawerVisible}
				bodyStyle={{padding: 0, margin: 0}}
			>
				<Divider style={{paddingTop: '0px'}}>
					<Space>
						<Avatar icon={<UserOutlined />} />
						{authUser && !collapsed ? authUser.name : ''}
					</Space>
				</Divider>
				{getSideMenu(authUser)}
				<br />
				<br />
				<p style={{textAlign: 'center'}}>
          App Version: {localStorage.getItem('appVersionName')}
				</p>
			</Drawer>
		</Layout>
	);
};
