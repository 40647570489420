import React, {useState} from 'react';
import {Input, Space, Card, Button, Form, Select, message} from 'antd';
import {PaperClipOutlined} from '@ant-design/icons';
import {checkFileErr, getS3Url, toTitleCase} from 'app/helpers';
import {update} from '../actions';
import {type ProjectFile, ProjectFileStatus} from 'app/components/Site/project-file';
import {useDispatch} from 'react-redux';
import {type UploadFile} from 'app/models/upload-file';
import {FileUpload} from '../../Common/FileUpload';

type Pops = {
	siteId: number;
	currentValue: ProjectFile;
	canEdit: boolean;
};

export const ProjectFileForm: React.FC<Pops> = ({currentValue, siteId, canEdit}) => {
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});
	const [fileStatus, setFileStatus] = React.useState(currentValue.fileStatus);
	const [description, setDescription] = React.useState(currentValue.description);

	const handleSubmit = () => {
		checkFileErr(fileList);
		if (fileStatus === ProjectFileStatus.UPLOAD_DOCUMENT && !fileList?.length) {
			void message.error('Please upload a file');
			return;
		}

		const projectFile = {
			siteId,
			fileStatus,
			description,
			fileType: currentValue.fileType,
			files: fileList.map((file: UploadFile) => `${filePrefixKeys[file.uid]}/${file.name}`).join(','),
		};
		dispatch(update(siteId, {projectFiles: [projectFile]}));
		setIsEditing(false);
	};

	return (
		<Space direction='vertical'>
			{isEditing ? (
				<Card>
					<Form.Item>
						<Space style={{width: '100%'}}>
							<Select
								style={{width: 200}}
								value={fileStatus}
								placeholder='Select upload option'
								onChange={value => {
									setFileStatus(value as ProjectFileStatus);
								}}
							>
								<Select.Option value={''}>Select Type</Select.Option>
								<Select.Option value={ProjectFileStatus.UPLOAD_DOCUMENT}>{toTitleCase(ProjectFileStatus.UPLOAD_DOCUMENT)}</Select.Option>
								<Select.Option value={ProjectFileStatus.WILL_UPLOAD_LATER}>{toTitleCase(ProjectFileStatus.WILL_UPLOAD_LATER)}</Select.Option>
								<Select.Option value={ProjectFileStatus.NOT_APPLICABLE}>{toTitleCase(ProjectFileStatus.NOT_APPLICABLE)}</Select.Option>
							</Select>
							<Input
								placeholder={'Enter Remarks'}
								defaultValue={description}
								onChange={e => {
									setDescription(String(e.target.value));
								}}
							/>
							<FileUpload
								label={'Click to Upload Files'}
								prefix={'agreement-docs'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={((fileList, filePrefixKeys) => {
									setFileList(fileList);
									setFilePrefixKeys(filePrefixKeys);
								})}
							/>
						</Space>
					</Form.Item>
					<Button type='primary' onClick={handleSubmit}>
            Save
					</Button>
					<Button type='primary' style={{marginLeft: '10px'}} onClick={() => {
						setIsEditing(false);
					}}>
            Cancel
					</Button>
				</Card>
			) : (
				<Space direction='vertical'>
					{currentValue?.fileStatus === ProjectFileStatus.UPLOAD_DOCUMENT ? (
						<>
							{currentValue?.files ? currentValue?.files.split(',').map((fileKey: string, ix: number) => (
								<p key={ix}>
									<PaperClipOutlined />
									{' '}
									<a href={getS3Url(fileKey)} target='_blank' rel='noreferrer'>
										{fileKey.split('/')[fileKey.split('/').length - 1]}
									</a>
								</p>
							)) : []}
						</>
					) : toTitleCase(currentValue?.fileStatus)}
					{currentValue?.description ?? ''}
					{canEdit ? (
						<Button type='primary' onClick={() => {
							setIsEditing(true);
						}}>Edit</Button>
					) : []}
				</Space>
			)}
		</Space>
	);
};
