import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {changePage} from './actions';
import {Col, Menu, Row, Typography} from 'antd';
import {queryPathType} from './constants';
import {ViewWarrantyQueryForm} from './ViewWarrantyQueryForm';
import {ViewWarrantyQueryRaised} from './ViewWarrantyQueryRaised';
import {ViewWarrantyQueryAssigned} from './ViewWarrantyQueryAssigned';
import {ViewWarrantyQueryAll} from './ViewWarrantyQueryAll';
import type Types from 'MyTypes';

export const ViewWarrantyQuery: React.FC = () => {
	const dispatch = useDispatch();
	const {pageType} = useSelector((state: Types.RootState) => state.warrantyQuery);
	const selectedPage = pageType ?? queryPathType.new;

	return (
		<DefaultLayout currentPath={uiPaths.warrantyQuery}>
			<Row>
				<Col span={24}>
					<Typography.Title level={3} style={{textAlign: 'center'}}>
            Warranty Queries
					</Typography.Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Menu
						onClick={e => dispatch(changePage(e.key))}
						selectedKeys={[selectedPage]}
						mode='horizontal'
					>
						<Menu.Item id={queryPathType.new} key={queryPathType.new}>
              Raise New Query
						</Menu.Item>
						<Menu.Item id={queryPathType.raised} key={queryPathType.raised}>
              Raised by Me
						</Menu.Item>
						<Menu.Item id={queryPathType.assigned} key={queryPathType.assigned}>
              Assigned to Me
						</Menu.Item>
						<Menu.Item id={queryPathType.all} key={queryPathType.all}>
              All Queries
						</Menu.Item>
					</Menu>
				</Col>
			</Row>

			<Row>
				<Col span={24}><br /></Col>
			</Row>

			<Row>
				<Col span={24}>
					{selectedPage === queryPathType.new ? (
						<ViewWarrantyQueryForm />
					) : []}
					{selectedPage === queryPathType.raised ? (
						<ViewWarrantyQueryRaised />
					) : []}
					{selectedPage === queryPathType.assigned ? (
						<ViewWarrantyQueryAssigned />
					) : []}
					{selectedPage === queryPathType.all ? (
						<ViewWarrantyQueryAll />
					) : []}
				</Col>
			</Row>

			<br />
			<br />
			<br />
		</DefaultLayout>
	);
};
