import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {
	Col,
	Empty,
	message,
	Row,
	Spin,
	Table,
	Typography,
} from 'antd';
import {uiPaths} from 'app/constants';
import {
	get,
	setFilterValue,
	count,
	onPaginationChange,
} from './actions';
import {FilterFormType, type FilterOptionItem} from 'app/models/ui-filter';
import {type FilterDataType, FilterView} from 'app/components/Common/Filter/FilterView';
import {PaginationView} from 'app/components/Common/Pagination/PaginationView';
import type Types from 'MyTypes';
import {type MaterialPurchaseOrder} from './material-purchase-order';
import {type MaterialMaster} from '../MaterialMaster/material-master';
import {getPermissionSites} from 'app/helpers';
import {ModuleName, ModulePermission} from 'app/models';
import {getVendors} from 'app/components/Common/summary-actions';

export const ViewAllMaterialPurchaseOrder: React.FC = () => {
	const dispatch = useDispatch();
	const {
		loading,
		byIds,
		allIds,
		filterValue,
		errorMessage,
		totalCount,
		currentPos,
		perPageSize,
	} = useSelector((state: Types.RootState) => state.materialPurchaseOrder);

	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);

	const {byIds: materialMasterByIds, allIds: allMaterialMasterIds} = useSelector(
		(state: Types.RootState) => state.materialMaster,
	);

	const {vendorOptions} = useSelector(
		(state: Types.RootState) => state.summary,
	);

	React.useEffect(() => {
		if (filterValue) {
			filterData(filterValue);
		}

		dispatch(getVendors());
	}, []);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const filterData = (f: FilterDataType = {}, currentPos = 0, perPageSize = 0) => {
		const filter: any = {
			where: {},
			limit: perPageSize,
			skip: currentPos ? perPageSize * (currentPos - 1) : 0,
		};

		if (f.name) {
			filter.where.name = {like: `%25${f.name as string}%25`};
		}

		if (f.siteId) {
			filter.where.siteId = Number(f.siteId);
		}

		if (f.itemId) {
			filter.where.itemId = Number(f.itemId);
		}

		if (f.costHeadId) {
			filter.where.costHeadId = Number(f.costHeadId);
		}

		if (f.vendorId) {
			filter.where.vendorId = Number(f.vendorId);
		}

		dispatch(setFilterValue(f));

		if (perPageSize && currentPos) {
			dispatch(get(filter));
		} else {
			dispatch(count(filter.where));
		}
	};

	const dataSource: MaterialPurchaseOrder[] = allIds?.length
		? allIds.map(id => byIds[id])
		: [];

	// Get permitted sites
	const sites = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_PURCHASE_ORDER,
		ModulePermission.READ,
		allSites,
	);

	const siteOptions: FilterOptionItem[] = sites.map(site => ({
		label: site.name,
		value: String(site.id),
	}));

	// Get items and cost heads from MaterialMaster
	const materialMasters: MaterialMaster[] = allMaterialMasterIds
		.map(id => materialMasterByIds[id]);

	const itemOptions = materialMasters
		.filter(material => !material.parentId)
		.map(item => ({value: String(item.id), label: item.name}));

	const costHeadOptions = (parentId: number) => materialMasters
		.filter(material => parentId && material.parentId === parentId)
		.map(item => ({value: String(item.id), label: item.name}));

	const uiFilters = [
		{
			filterKey: 'name',
			formType: FilterFormType.TEXT,
			placeholder: 'Enter Name',
			label: 'Name',
			defaultValue: filterValue ? filterValue.name : '',
		},
		{
			filterKey: 'siteId',
			formType: FilterFormType.SELECT,
			placeholder: 'Select Site',
			label: 'Site',
			defaultValue: filterValue ? filterValue.siteId : undefined,
			options: siteOptions,
		},
		{
			filterKey: 'itemId',
			formType: FilterFormType.SELECT,
			placeholder: 'Select Item',
			label: 'Item',
			defaultValue: filterValue ? filterValue.itemId : undefined,
			options: itemOptions,
		},
		{
			filterKey: 'costHeadId',
			formType: FilterFormType.SELECT,
			placeholder: 'Select Cost Head',
			label: 'Cost Head',
			defaultValue: filterValue ? filterValue.costHeadId : undefined,
			options: costHeadOptions(Number(filterValue?.itemId)) || [],
			dependencies: ['itemId'],
		},
		{
			filterKey: 'vendorId',
			formType: FilterFormType.SELECT,
			placeholder: 'Select Vendor',
			label: 'Vendor',
			defaultValue: filterValue ? filterValue.vendorId : undefined,
			options: vendorOptions,
		},
	];

	return (
		<DefaultLayout currentPath={uiPaths.allCategories}>
			<Spin size='large' spinning={loading} tip={'Loading...'}>
				<Row>
					<Col span={24}>
						<Typography.Title level={3} style={{textAlign: 'center'}}>
                            Material Purchase Order
						</Typography.Title>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<FilterView
							uiFilters={uiFilters}
							onSubmit={(f: FilterDataType) => {
								filterData(f);
							}}
						/>
					</Col>
				</Row>

				{totalCount ? (
					<Row>
						<Col span={24}>
							<PaginationView
								isFunctional={true}
								total={totalCount}
								currentPos={currentPos}
								perPageSize={perPageSize}
								filterValue={filterValue}
								filterData={filterData}
								onPaginationChange={onPaginationChange}
							/>
							<Table
								size={'small'}
								bordered={true}
								dataSource={dataSource}
								pagination={false}
							>
								<Table.Column
									width={500}
									title='Name'
									dataIndex='name'
									key='name'
								/>
								<Table.Column
									title='Base Price'
									dataIndex='basePrice'
									key='basePrice'
								/>
								<Table.Column
									title='GST %'
									dataIndex='gstPercent'
									key='gstPercent'
								/>
								<Table.Column
									title='Total Cost'
									dataIndex='totalCost'
									key='totalCost'
								/>
							</Table>
						</Col>
					</Row>
				) : (
					<Row>
						<Col span={24} style={{textAlign: 'center'}}>
							<Empty />
						</Col>
					</Row>
				)}
			</Spin>
		</DefaultLayout>
	);
};
