import {lbBaseUrl} from 'app/constants';
import axios from 'axios';
import {getHeaders} from 'app/services/common-service';
import {type FilterDataType} from '../Common/Filter/FilterView';

export const actionTypes = {
	apiFilter: '@materialMaster/API_FILTER',
	getApi: '@materialMaster/GET_API',
	getSingleApi: '@materialMaster/GET_SINGLE_API',
	getParentApi: '@materialMaster/GET_PARENT_API',
	createApi: '@materialMaster/CREATE_API',
	updateApi: '@materialMaster/UPDATE_API',
	paginationChange: '@materialMaster/PAGINATION_CHANGE',
	getCountApi: '@materialMaster/GET_COUNT_API',
};

const url = lbBaseUrl + '/material-masters';

export const setFilterValue = (filterValue: FilterDataType) => ({
	type: actionTypes.apiFilter,
	payload: filterValue,
	meta: {},
});

export const get = (filter: any) => ({
	type: actionTypes.getApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify(filter)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getParents = () => ({
	type: actionTypes.getParentApi,
	payload: axios.get(
		`${url}?filter=${JSON.stringify({where: {parentId: 0}})}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const getById = (id: number) => ({
	type: actionTypes.getSingleApi,
	payload: axios.get(`${url}/${id}`, getHeaders(true)),
	meta: {process: true},
});

export const onPaginationChange = (
	currentPos: number,
	perPageSize: number,
) => ({
	type: actionTypes.paginationChange,
	payload: {currentPos, perPageSize},
	meta: {},
});

export const count = (where: any) => ({
	type: actionTypes.getCountApi,
	payload: axios.get(
		`${url}/count?where=${JSON.stringify(where)}`,
		getHeaders(true),
	),
	meta: {process: true},
});

export const create = (data: any) => ({
	type: actionTypes.createApi,
	payload: axios.post(`${url}`, data, getHeaders(true)),
	meta: {process: true},
});

export const update = (id: number, data: any) => ({
	type: actionTypes.updateApi,
	payload: axios.patch(`${url}/${id}`, data, getHeaders(true)),
	meta: {process: true},
});
