import React from 'react';
import {
	message,
	Spin,
	Row,
	Col,
	Card,
	Form,
	Select,
	Input,
	Button,
} from 'antd';
import {type QueryData, type AttachmentData, ModuleName, ModulePermission, type Site, type User, type UserPermission, UserType, ExtraModulePermission} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {type UploadFile} from 'app/models/upload-file';
import {useDispatch, useSelector} from 'react-redux';
import {create, initPage} from './actions';
import {checkFileErr, equalNum, parseNum, toTitleCase} from 'app/helpers';
import type Types from 'MyTypes';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {SiteSelect} from '../Common/SiteSelect';
import {get as getUserPermissions} from 'app/components/UserPermission/actions';

export const ViewWarrantyQueryForm: React.FC = () => {
	const dispatch = useDispatch();
	const {loading, dataUpdated} = useSelector(
		(state: Types.RootState) => state.warrantyQuery,
	);
	const {
		allSites: sites,
		allIds: userPermissionAllIds,
		byIds: userPermissionByIds,
	} = useSelector((state: Types.RootState) => state.userPermission);
	const userPermissions = userPermissionAllIds.map(
		id => userPermissionByIds[id],
	);
	const {user: authUser} = useSelector(
		(state: Types.RootState) => state.summary,
	);
	const userType = authUser?.userType;

	let existingSiteId: number | undefined;
	if (sites?.length === 1) {
		existingSiteId = sites[0].id;
	}

	const siteOptions: FilterOptionItem[] = sites.map((s: Site) => ({
		value: s.id.toString(),
		label: s.name,
	}));

	const [assignedTo, setAssignedTo] = React.useState<number | undefined>();
	const [siteId, setSiteId] = React.useState<number | undefined>(existingSiteId);
	const [content, setContent] = React.useState<string>('');
	const [fileList, setFileList] = React.useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});

	React.useEffect(() => {
		dispatch(initPage());
		dispatch(
			getUserPermissions({
				where: {
					siteId: {inq: sites.map(({id}) => id)},
					permission: ExtraModulePermission.ASSIGNABLE,
					moduleName: ModuleName.WARRANTY_QUERY,
				},
			}),
		);
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	const onSubmitClicked = () => {
		if (!siteId) {
			void message.error('Please select a site');
			return;
		}

		if (!assignedTo) {
			void message.error('Please Select Whom to Assign this query');
			return;
		}

		if (!content) {
			void message.error('Please Enter Query Point');
			return;
		}

		checkFileErr(fileList);

		const attachments: AttachmentData[] = fileList.map(
			(file: UploadFile) => ({
				name: file.name,
				key: `${filePrefixKeys[file.uid]}/${file.name}`,
			}),
		);

		const data: QueryData = {
			siteId,
			content,
			assignedTo,
			attachments,
		};

		dispatch(create(data));
	};

	const getAssignOptions = () => userPermissions
		?.filter(
			userPermission =>
				!equalNum(userPermission.userId, authUser?.id)
				&& equalNum(userPermission.siteId, siteId),
		)
		.reduce((opts: FilterOptionItem[], p: UserPermission) => {
			let u: User | undefined;
			if (p?.user?.userType !== userType) {
				u = p?.user;
			}

			if (
				u?.id
				&& u?.name
				&& !opts.find(opt => equalNum(opt.value, u?.id))
			) {
				opts.push({
					label: `${u.name} (${toTitleCase(u.roles)})`,
					value: String(u.id),
				});
			}

			if (userType === UserType.CLIENT) {
				opts = opts.filter(user => !user.label.includes('Client'));
			} else {
				opts = opts.filter(user => user.label.includes('Client'));
			}

			console.log(opts);
			return opts;
		}, []);

	const layout = {
		labelCol: {span: 6},
		wrapperCol: {span: 18},
	};

	const tailLayout = {
		wrapperCol: {offset: 6, span: 18},
	};

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row className='mb-15'>
				<Col span={24}>
					<Form {...layout} name='basic'>
						{siteOptions.length > 1 && !existingSiteId ? (
							<Form.Item label='Site/Project*'>
								<SiteSelect
									siteOptions={siteOptions}
									style={{width: '100%'}}
									placeholder='Select a Site'
									onChange={value => {
										setSiteId(parseNum(String(value)));
									}}
								/>
							</Form.Item>
						) : (
							[]
						)}

						<Form.Item
							label='Assign this Query to*'
							required={true}
							rules={[{required: true, message: 'Please select whom to assign'}]}
						>
							<Select
								id={'assignedTo'}
								showSearch={true}
								style={{width: '100%'}}
								placeholder='Select For Whom'
								optionFilterProp='children'
								onChange={value => {
									setAssignedTo(parseNum(String(value)));
								}}
							>
								{getAssignOptions().map((option: FilterOptionItem, ix: number) => (
									<Select.Option key={ix} value={option.value}>
										{option.label}
									</Select.Option>
								))}
								<Select.Option key={-1} value={-1}>
									{'I don\'t know whom to assign'}
								</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item
							label='Query Point*'
							required={true}
							rules={[{required: true, message: 'Please enter your query'}]}
						>
							<Input.TextArea
								id={'content'}
								rows={3}
								placeholder={'Enter Your Query'}
								value={content}
								onChange={(e: any) => {
									setContent(String(e.target.value));
								}}
							/>
						</Form.Item>

						<Form.Item label='Attachments (if any)'>
							<FileUpload
								prefix={'query'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={(fileList, filePrefixKeys) => {
									setFileList(fileList);
									setFilePrefixKeys(filePrefixKeys);
								}}
							/>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button id={'submit'} type='primary' onClick={onSubmitClicked}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>

				<Col span={24}>
					<Card size='small' title={'Important note'}>
						<ul>
							<li>
								- If you want to edit or delete the raised query or assign it
								to a different person, mark the raised query as Closed and
								raise a fresh query.
							</li>
						</ul>
					</Card>
				</Col>
			</Row>
		</Spin>
	);
};
