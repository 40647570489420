export type ProjectFile = {
	id?: number;
	siteId: number;
	description?: string;
	files?: string;
	fileUrl?: string;
	fileType?: ProjectFileType;
	fileStatus?: ProjectFileStatus;
};

export enum ProjectFileStatus {
	NONE = '',
	UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
	NOT_APPLICABLE = 'NOT_APPLICABLE',
	WILL_UPLOAD_LATER = 'WILL_UPLOAD_LATER',
}

export enum ProjectFileType {
	SCOPE_OF_WORK = 'Scope Of Work',
	CONSTRUCTION_TIMELINE = 'Construction Timeline',
	AREA_STATEMENT_WITHOUT_PRICING = 'Area Statement Without Pricing',
	AGREEMENT = 'Agreement',
	TITLE_DOCUMENTS = 'Title Documents',
	AREA_STATEMENT_WITH_PRICING = 'Area Statement With Pricing',
	PAYMENT_PLAN = 'Payment Plan',
	WARRANTY_DOCUMENTS = 'Warranty Documents',
	FINAL_HANDOVER_LETTER = 'Final Handover Letter',
	ANY_OTHER_DOCUMENT = 'Other Document',
}

export const svrProjectFileTypes = [
	ProjectFileType.TITLE_DOCUMENTS,
	ProjectFileType.AREA_STATEMENT_WITH_PRICING,
	ProjectFileType.PAYMENT_PLAN,
	ProjectFileType.WARRANTY_DOCUMENTS,
	ProjectFileType.ANY_OTHER_DOCUMENT,
];
