import {type CommentPolicy} from './policies/comment.policy';
import {type User} from './user.model';
import {type Attachment} from 'app/models/attachment.model';
import {ModuleName} from './module.model';

export type Comment = {
	id: number;
	content: string;
	attachmentStr: string;
	isViewed: boolean;
	visibility?: VisibilityType;
	commentType?: CommentType;
	createdAt?: string;
	updatedAt?: string;
	createdBy?: number;
	updatedBy?: number;
	queryId?: number;
	materialQueryId?: number;
	expenseReportId?: number;
	can?: CommentPolicy;
	createdUser?: User;
	attachments: Attachment[];
};

export enum VisibilityType {
	INTERNAL = 'INTERNAL',
	CLIENT = 'CLIENT',
	VENDOR = 'VENDOR',
	EVERYONE = 'EVERYONE',
}

export enum CommentType {
	ESCALATE_QUERY = 'ESCALATE_QUERY',
	RE_ASSIGN_QUERY = 'RE_ASSIGN_QUERY',
	ASSIGN_QUERY = 'ASSIGN_QUERY',
}

export enum CommentParentType {
	QUERY = 'QUERY',
	CORPORATE_QUERY = 'CORPORATE_QUERY',
	CLIENT_QUERY = 'CLIENT_QUERY',
	MATERIAL_QUERY = 'MATERIAL_QUERY',
	PETTY_CASH = 'PETTY_CASH',
	PROCESS_CHECKLIST_RESPONSE = 'PROCESS_CHECKLIST_RESPONSE',
	PROJECT_SCHEDULE = 'PROJECT_SCHEDULE',
	CONTRACTOR_BILL = 'CONTRACTOR_BILL',
	WORK_ORDER_QUERY = 'WORK_ORDER_QUERY',
	WORK_ORDER_APPROVE = 'WORK_ORDER_APPROVE',
	WARRANTY_QUERY = 'WARRANTY_QUERY',
}

export const commentTypeLabels = {
	[CommentType.ESCALATE_QUERY]: 'Query Escalated',
	[CommentType.RE_ASSIGN_QUERY]: 'Query Reassigned',
	[CommentType.ASSIGN_QUERY]: 'Query Assigned',
};

export const moduleCommentType = {
	[ModuleName.QUERY]: CommentParentType.QUERY,
	[ModuleName.CORPORATE_QUERY]: CommentParentType.CORPORATE_QUERY,
	[ModuleName.CLIENT_QUERY]: CommentParentType.CLIENT_QUERY,
	[ModuleName.MATERIAL_QUERY]: CommentParentType.MATERIAL_QUERY,
	[ModuleName.WORK_ORDER_QUERY]: CommentParentType.WORK_ORDER_QUERY,
	[ModuleName.WARRANTY_QUERY]: CommentParentType.WARRANTY_QUERY,
};
