export enum ModuleName {
	WALLET = 'WALLET',
	EXPENSE_REPORT = 'EXPENSE_REPORT',
	EXPENSE_REPORT_PAY = 'EXPENSE_REPORT_PAY',
	QUERY = 'QUERY',
	CLIENT_QUERY = 'CLIENT_QUERY',
	CORPORATE_QUERY = 'CORPORATE_QUERY',
	WARRANTY_QUERY = 'WARRANTY_QUERY',
	MATERIAL_QUERY = 'MATERIAL_QUERY',
	MATERIAL_QUERY_ORDER = 'MATERIAL_QUERY_ORDER',
	MATERIAL_QUERY_RECIEVE = 'MATERIAL_QUERY_RECIEVE',
	MATERIAL_QUERY_ESCLATE = 'MATERIAL_QUERY_ESCLATE',
	WORK_STATUS_REPORT = 'WORK_STATUS_REPORT',
	DRAWING_SCHEDULE = 'DRAWING_SCHEDULE',
	DRAWING_SCHEDULE_PROCESS_DOCUMENT = 'DRAWING_SCHEDULE_PROCESS_DOCUMENT',
	DRAWING_SCHEDULE_REPORT = 'DRAWING_SCHEDULE_REPORT',
	APPROVED_EXPENSE_REPORT = 'APPROVED_EXPENSE_REPORT',
	EXPENSE_SUMMARY = 'EXPENSE_SUMMARY',
	PROJECT_HANDOVER_SCHEDULE = 'PROJECT_HANDOVER_SCHEDULE',
	WEAKLY_STATUS_REPORT = 'WEAKLY_STATUS_REPORT',
	QUERY_REPORT = 'QUERY_REPORT',
	WORK_ORDER = 'WORK_ORDER',
	WORK_ORDER_DEBIT = 'WORK_ORDER_DEBIT',
	WORK_ORDER_REPORT = 'WORK_ORDER_REPORT',
	WORK_ORDER_BILL = 'WORK_ORDER_BILL',
	WORK_ORDER_BILL_PAY = 'WORK_ORDER_BILL_PAY',
	WORK_ORDER_BILL_REPORT = 'WORK_ORDER_BILL_REPORT',
	WORK_ORDER_SCHEDULE = 'WORK_ORDER_SCHEDULE',
	WORK_ORDER_BUDGET = 'WORK_ORDER_BUDGET',
	PROJECT_PROCESS_MASTER = 'PROJECT_PROCESS_MASTER',
	CONSTRUCTION_SCHEDULE = 'CONSTRUCTION_SCHEDULE',
	APPROVAL_SCHEDULE = 'APPROVAL_SCHEDULE',
	PROCESS_CHECKLIST = 'PROCESS_CHECKLIST',
	PROCESS_CHECKLIST_RESPONSE = 'PROCESS_CHECKLIST_RESPONSE',
	PROJECT_DETAIL = 'PROJECT_DETAIL',
	PROJECT_HANDOVER_REPORT = 'PROJECT_HANDOVER_REPORT',
	PROJECT_QUERY_ASSIGNMENT = 'PROJECT_QUERY_ASSIGNMENT',
	PROJECT_BUDGET_QUANTITIES = 'PROJECT_BUDGET_QUANTITIES',
	MATERIAL_ORDER_DAILY_QUANTITY = 'MATERIAL_ORDER_DAILY_QUANTITY',
	ON_SITE_ATTENDANCE = 'ON_SITE_ATTENDANCE',
	LABOUR_ATTENDANCE = 'LABOUR_ATTENDANCE',
	SCHEDULE_MANUAL_ENTRY = 'SCHEDULE_MANUAL_ENTRY',
	USER_PERMISSIONS = 'USER_PERMISSIONS',
	ACTIVITY_TEMPLATE = 'ACTIVITY_TEMPLATE',
	PROJECT_ACTIVITY = 'PROJECT_ACTIVITY',
	ELECTRICITY_CONSUMPTION = 'ELECTRICITY_CONSUMPTION',
	WARRANTY_DOCUMENTS = 'WARRANTY_DOCUMENTS',
	CATEGORY = 'CATEGORY',
	WORK_ORDER_QUERY = 'WORK_ORDER_QUERY',
	WARRANTY_PERIODS = 'WARRANTY_PERIODS',
	PAYMENT_MILESTONE = 'PAYMENT_MILESTONE',
	MATERIAL_PURCHASE_ORDER = 'MATERIAL_PURCHASE_ORDER',
	MATERIAL_MASTER = 'MATERIAL_MASTER',
}

export const moduleLabelName: Record<string, string> = {
	[ModuleName.WALLET]: 'Wallet',
	[ModuleName.EXPENSE_REPORT]: 'Petty Cash',
	[ModuleName.EXPENSE_REPORT_PAY]: 'Petty Cash Payment',
	[ModuleName.WARRANTY_QUERY]: 'Warranty Query',
	[ModuleName.WORK_STATUS_REPORT]: 'Work Status Report',
	[ModuleName.WEAKLY_STATUS_REPORT]: 'Weekly Status Report',
	[ModuleName.EXPENSE_SUMMARY]: 'Expense Summary',
	[ModuleName.CLIENT_QUERY]: 'Client Query',
	[ModuleName.MATERIAL_QUERY]: 'Material Query',
	[ModuleName.MATERIAL_QUERY_ORDER]: 'Material Query Order',
	[ModuleName.MATERIAL_QUERY_RECIEVE]: 'Material Query Receive',
	[ModuleName.PROJECT_HANDOVER_REPORT]: 'Project Handover Report',
	[ModuleName.CORPORATE_QUERY]: 'Corporate Query',
	[ModuleName.QUERY]: 'Project Query',
	[ModuleName.MATERIAL_QUERY_ESCLATE]: 'Material Query Escalate',
	[ModuleName.DRAWING_SCHEDULE]: 'Drawing Schedule',
	[ModuleName.DRAWING_SCHEDULE_PROCESS_DOCUMENT]: 'Drawing Schedule Process Document',
	[ModuleName.DRAWING_SCHEDULE_REPORT]: 'Drawing Schedule Report',
	[ModuleName.APPROVED_EXPENSE_REPORT]: 'Approved Expense Report',
	[ModuleName.WORK_ORDER]: 'Work Order',
	[ModuleName.WORK_ORDER_DEBIT]: 'Work Order Debit',
	[ModuleName.WORK_ORDER_REPORT]: 'Work Order Report',
	[ModuleName.WORK_ORDER_BILL]: 'Contractor Bill',
	[ModuleName.WORK_ORDER_BILL_PAY]: 'Contractor Bill Payment',
	[ModuleName.WORK_ORDER_BILL_REPORT]: 'Contractor Bill Report',
	[ModuleName.WORK_ORDER_SCHEDULE]: 'Work Order Schedule',
	[ModuleName.WORK_ORDER_BUDGET]: 'Work Order Budget',
	[ModuleName.PROJECT_PROCESS_MASTER]: 'Project Schedule Tab Masters',
	[ModuleName.PROJECT_HANDOVER_SCHEDULE]: 'Project Hand Over Schedule',
	[ModuleName.CONSTRUCTION_SCHEDULE]: 'Construction Schedule',
	[ModuleName.APPROVAL_SCHEDULE]: 'Approval Schedule',
	[ModuleName.ELECTRICITY_CONSUMPTION]: 'Electricity Consumption',
	[ModuleName.PROCESS_CHECKLIST]: 'Process Checklist Masters',
	[ModuleName.PROCESS_CHECKLIST_RESPONSE]: 'Checklist Response',
	[ModuleName.PROJECT_DETAIL]: 'Project Detail',
	[ModuleName.PROJECT_QUERY_ASSIGNMENT]: 'Project Query Assignment',
	[ModuleName.SCHEDULE_MANUAL_ENTRY]: 'Schedule Manual Entry',
	[ModuleName.LABOUR_ATTENDANCE]: 'Labour Attendance',
	[ModuleName.USER_PERMISSIONS]: 'User Permissions',
	[ModuleName.PROJECT_ACTIVITY]: 'Project Activities',
	[ModuleName.WARRANTY_DOCUMENTS]: 'Warranty Documents',
	[ModuleName.CATEGORY]: 'Category',
	[ModuleName.WORK_ORDER_QUERY]: 'Work Order Query',
	[ModuleName.WARRANTY_PERIODS]: 'Warranty Periods',
	[ModuleName.PAYMENT_MILESTONE]: 'Payment Milestone',
	[ModuleName.MATERIAL_PURCHASE_ORDER]: 'Purchase Order',
	[ModuleName.MATERIAL_MASTER]: 'Material Master',
};

export const adminModuleLabelName: Record<string, string> = {
	[ModuleName.PROJECT_HANDOVER_REPORT]: 'Project Handover Report',
};

export enum ModulePermission {
	READ = 'READ',
	WRITE = 'WRITE',
	APPROVE = 'APPROVE',
}

export enum ExtraModulePermission {
	RESOLVE = 'RESOLVE',
	UPDATE_STATUS = 'UPDATE_STATUS',
	CANCEL_ONLY = 'CANCEL_ONLY',
	ASSIGNABLE = 'ASSIGNABLE',
}

export const permissionLabels: Record<string, string> = {
	[ModulePermission.READ]: 'View',
	[ModulePermission.WRITE]: 'Edit',
	[ModulePermission.APPROVE]: 'Approve',
	[ExtraModulePermission.UPDATE_STATUS]: 'Update Status',
	[ExtraModulePermission.CANCEL_ONLY]: 'Cancel Only',
	[ExtraModulePermission.ASSIGNABLE]: 'Assignable',
};

export const extraModuleWisePermissions: Record<string, string[]> = {
	[ModuleName.WORK_ORDER_QUERY]: [ExtraModulePermission.RESOLVE, ExtraModulePermission.UPDATE_STATUS, ExtraModulePermission.CANCEL_ONLY],
	[ModuleName.WARRANTY_QUERY]: [ExtraModulePermission.ASSIGNABLE],
};
